import React, { useState, useEffect } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import styles from "./index.module.scss";

export default function SortBy({
  setMenuOpen,
  options,
  label = "Sort By",
  role,
}) {
  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const containerStyle = {
    width: "10rem",
    borderRadius: "8px", // Add border radius
    // Ensure the border radius is visible
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px", // Apply border radius to the input
    },
  };

  return (
    <div className={role === "sales" ? styles["sort"] : styles["sort-by"]}>
      <FormControl
        variant="outlined"
        // style={{ width: "7rem" }}
        sx={containerStyle}
      >
        <InputLabel
          id="demo-simple-select-outlined-label"
          sx={{
            paddingBlock: 0,
            "&.MuiInputLabel-shrink": {
              // paddingBlock: 1, // Apply padding only when the label is shrunk
            },
          }}
        >
          {label}
        </InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={selectedValue}
          onChange={handleChange}
          onOpen={() => setMenuOpen(true)}
          onClose={() => setMenuOpen(false)}
          //   sx={{ position: "sticky" }}
          label="Sort By"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            getContentAnchorEl: null,
            PaperProps: {
              style: {
                maxHeight: "12rem",
                width: "12.75rem",
                height: "auto",
                overflow: "hidden",
                borderRadius: "8px",
                marginTop: "5px",
              },
            },
          }}
        >
          {options.map((option, index) => (
            <MenuItem
              key={index}
              onClick={option?.onclick}
              value={option?.value}
            >
              {option?.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
