import { useEffect, useRef, useState } from "react";
import { Collapse } from "@mui/material";
import {
  dateFormatWithMonthInName,
  dateFormatToTimeAndDate,
} from "../../utils/formatDate";
import styles from "./index.module.scss";

import DownIcon from "../../assets/icon-down.-black.svg";

const ContainersAndEvents = ({ shipmentInfo }) => {
  const { searates_body } = shipmentInfo;
  const containers = searates_body?.containers;
  const locations = searates_body?.locations;
  const facilities = searates_body?.facilities;
  const vessels = searates_body?.vessels;

  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState({});
  const [selectedContainer, setSelectedContainer] = useState(containers[0]);
  const dropdownRef = useRef(null);

  const selectedContainerEvents = selectedContainer?.events;

  const eventsStatus = {
    CEP: "Container empty to shipper",
    CPS: "Container pickup at shipper",
    CGI: "Container arrival at first POL (Gate in)",
    CLL: "Container loaded at first POL",
    VDL: "Vessel departure from first POL",
    VAT: "Vessel arrival at T/S port",
    CDT: "Container discharge at T/S port",
    TSD: "Transshipment Delay",
    CLT: "Container loaded at T/S port",
    VDT: "Vessel departure from T/S",
    VAD: "Vessel arrival at final POD",
    CDD: "Container discharge at final POD",
    CGO: "Container departure from final POD (Gate out)",
    CDC: "Container delivery to consignee",
    CER: "Container empty return to depot",
    LTS: "Land transhipment",
    BTS: "Barge transhipment",
    UNK: "Unknown",
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (selectedOption) => {
    setSelectedContainer(selectedOption);
    setIsOpen(!isOpen);
  };

  const handleExpandedClick = (orderId) => {
    setIsExpanded((prev) => ({
      ...prev,
      [orderId]: !prev[orderId],
    }));
  };

  const getEventLocation = (eventLocationId) => {
    const location = locations?.find(
      (location) => location?.id === eventLocationId
    );
    return location;
  };
  const getEventFacility = (eventFacility) => {
    const facility = facilities?.find(
      (facility) => facility?.id === eventFacility
    );
    return facility;
  };
  const getEventVessel = (eventVessel) => {
    const vessel = vessels?.find((vessel) => vessel?.id === eventVessel);
    return vessel;
  };

  // Handle Dropdown Click Outside.
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const statusClasses = {
    IN_TRANSIT: styles.active,
    PLANNED: styles.new,
    DELIVERED: styles.accepted,
    UNKNOWN: styles.expired,
  };

  // console.log("containers", containers);
  // console.log("searates_body", searates_body);
  // console.log("locations", locations);
  // console.log("facilities", facilities);
  // console.log("vessels", vessels);
  // console.log("selectedContainer", selectedContainer);
  // console.log("selectedContainerEvents", selectedContainerEvents);

  return (
    <div className={styles["containerEvents"]}>
      <div className={styles["containerEvents-header"]}>
        <div className={styles.sortDropdown} ref={dropdownRef}>
          <button
            className={styles["sortDropdown-filterButton"]}
            onClick={toggleDropdown}
          >
            <span>{selectedContainer?.number}</span>
            <img
              className={`${styles.icon} ${isOpen ? styles.rotated : ""}`}
              src={DownIcon}
              alt="Down Arrow"
            />
          </button>

          {isOpen && (
            <div className={styles.dropdownMenu}>
              {containers?.map((container) => (
                <div
                  key={container?.number}
                  className={styles.dropdownItem}
                  onClick={() => handleOptionClick(container)}
                >
                  {container?.number}
                </div>
              ))}
            </div>
          )}
        </div>

        {selectedContainer && (
          <>
            <div className={styles["containerEvents-sizeType"]}>
              {selectedContainer?.size_type}
            </div>
            <p
              className={`${
                statusClasses[
                  selectedContainer.status === "in_transit"
                    ? "active"
                    : selectedContainer.status
                ]
              } ${styles["containerEvents-status"]}`}
            >
              {selectedContainer?.status.toLowerCase().replaceAll("_", " ")}
            </p>
          </>
        )}
      </div>

      <div className={styles["containerEvents-events"]}>
        {selectedContainerEvents
          ?.sort((a, b) => b.order_id - a.order_id)
          ?.map((containerEvent) => {
            const isEventExpanded = isExpanded[containerEvent.order_id];
            const eventLocation = getEventLocation(containerEvent.location);
            const eventFacility = getEventFacility(containerEvent.facility);
            const eventVessel = getEventVessel(containerEvent.vessel);

            return (
              <div
                key={containerEvent?.order_id}
                className={styles["event-item"]}
              >
                <div className={styles["event-row"]}>
                  <div
                    className={styles["event-type"]}
                    onClick={() => handleExpandedClick(containerEvent.order_id)}
                  >
                    <img
                      src={DownIcon}
                      className={!isEventExpanded ? styles["icon"] : ""}
                      alt="DownIcon"
                    />
                    <p>
                      {`${containerEvent.event_type.toLowerCase()} - ${
                        containerEvent.description
                      }`}
                    </p>
                  </div>
                  {!isEventExpanded && (
                    <>
                      <div className={styles["event-location"]}>
                        {eventLocation?.name}
                      </div>
                      <div className={styles["event-date"]}>
                        {dateFormatWithMonthInName(containerEvent.date)}
                      </div>
                    </>
                  )}
                </div>

                <Collapse in={isEventExpanded} timeout="auto" unmountOnExit>
                  <div className={styles["event-details"]}>
                    <div className={styles["event-details-column"]}>
                      <p>
                        <span>Status: </span>
                        <span>{eventsStatus[containerEvent.status]}</span>
                      </p>

                      <p>
                        <span>Location: </span>
                        <span>{`${eventLocation?.name}, ${eventFacility?.name}`}</span>
                      </p>

                      <p>
                        <span>State & Country: </span>
                        <span>{`${eventLocation?.state}, ${eventLocation?.country}`}</span>
                      </p>

                      <p>
                        <span>Time & Date: </span>
                        <span>{`${dateFormatToTimeAndDate(
                          containerEvent.date
                        )} - ${
                          containerEvent.actual === "False"
                            ? "Estimated"
                            : "Confirmed"
                        }`}</span>
                      </p>

                      {containerEvent.transport_type !== "None" && (
                        <p>
                          <span>Transport Type: </span>
                          <span>{containerEvent.transport_type}</span>
                        </p>
                      )}

                      {containerEvent.voyage !== "None" && (
                        <p>
                          <span>Voyage: </span>
                          <span>{containerEvent.voyage}</span>
                        </p>
                      )}
                    </div>
                    {eventVessel && (
                      <div className={styles["event-details-column"]}>
                        <p>
                          <span>Vessel Name: </span>
                          <span>{eventVessel.name}</span>
                        </p>
                        <p>
                          <span>Vessel IMO: </span>
                          <span>{eventVessel.imo}</span>
                        </p>
                        <p>
                          <span>Vessel MMSI: </span>
                          <span>{eventVessel.mmsi}</span>
                        </p>
                        <p>
                          <span>Vessel Call Sign: </span>
                          <span>{eventVessel.call_sign}</span>
                        </p>
                        <p>
                          <span>Vessel Flag: </span>
                          <span>{eventVessel.flag}</span>
                        </p>
                      </div>
                    )}
                  </div>
                </Collapse>
              </div>
            );
          })}
      </div>

      {/* <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        {selectedContainerEvents?.map((event) => (
          <div key={event?.id} className={styles["containerEvents-event"]}>
            <div>{event?.date}</div>
          </div>
        ))}
      </Collapse> */}
    </div>
  );
};

export default ContainersAndEvents;
