import { useState } from "react";
import styles from "./index.module.scss";
import { Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import DateInput from "../dateInput";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import moment from "moment";
export default function FiltersSidebar({
  bounds,
  dispatch,
  filters,
  setFiltersApplied,
  shouldShowPrice,
}) {
  const [price, setPrice] = useState([null, null]);
  const [transit, setTransit] = useState([
    bounds.minTransitTime,
    bounds.maxTransitTime,
  ]);
  const [dateFields, setDateFields] = useState({
    etaFrom: null,
    etaTo: null,
    etdFrom: null,
    etdTo: null,
  });
  const handlePriceChange = (event) => {
    setPrice(event.target.value);
    // setFiltersApplied(true);

    // setTransit(filters.transitTimeRange);
  };
  const handleTransitTimeChange = (event) => {
    setTransit(event.target.value);
    // setFiltersApplied(true);

    // setPrice(filters.priceRange);
  };
  const handleETAFrom = (from) => {
    const formattedDate = moment(from.$d).format("YYYY-MM-DD");
    setDateFields({
      ...dateFields,
      etaFrom: formattedDate,
    });
    // setFiltersApplied(true);

    // setPrice(filters.priceRange);
    // setTransit(filters.transitTimeRange);
  };
  const handleETATo = (to) => {
    const formattedDate = moment(to.$d).format("YYYY-MM-DD");
    setDateFields({
      ...dateFields,
      etaTo: formattedDate,
    });
    // setFiltersApplied(true);

    // setPrice(filters.priceRange);
    // setTransit(filters.transitTimeRange);
  };
  const handleETDFrom = (from) => {
    const formattedDate = moment(from.$d).format("YYYY-MM-DD");
    setDateFields({
      ...dateFields,
      etdFrom: formattedDate,
    });
    // setFiltersApplied(true);

    // setPrice(filters.priceRange);
    // setTransit(filters.transitTimeRange);
  };
  const handleETDTo = (to) => {
    const formattedDate = moment(to.$d).format("YYYY-MM-DD");
    setDateFields({
      ...dateFields,
      etdTo: formattedDate,
    });
    // setFiltersApplied(true);

    // setPrice(filters.priceRange);
    // setTransit(filters.transitTimeRange);
  };

  // console.log("filtes", filters);
  // console.log("bounds", bounds);
  // console.log("price", price);
  // console.log("transit", transit);

  const handleApplyClick = () => {
    setFiltersApplied(true);
    dispatch({ type: "SET_PRICE_RANGE", payload: price });
    dispatch({ type: "SET_TRANSIT_TIME_RANGE", payload: transit });
    dispatch({
      type: "SET_ETA_RANGE",
      payload: [dateFields.etaFrom, dateFields.etaTo],
    });
    dispatch({
      type: "SET_ETD_RANGE",
      payload: [dateFields.etdFrom, dateFields.etdTo],
    });
  };
  const handleClearClick = () => {
    dispatch({
      type: "SET_PRICE_RANGE",
      payload: [null, null],
    });
    setPrice([null, null]);
    dispatch({
      type: "SET_TRANSIT_TIME_RANGE",
      payload: [null, null],
    });
    setTransit([null, null]);
    dispatch({
      type: "SET_ETA_RANGE",
      payload: [null, null],
    });
    dispatch({
      type: "SET_ETD_RANGE",
      payload: [null, null],
    });

    setDateFields({ etaFrom: null, etaTo: null, etdFrom: null, etdTo: null });
  };

  return (
    <aside className={styles.filtersSidebar}>
      <h2>Filters</h2>
      <div className={styles.filters}>
        {shouldShowPrice ? (
          <div className={styles.filter}>
            <h3>Price</h3>
            <div className={styles.filter}>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "8px",
                  }}
                >
                  <span>{price[0] ? price[0] : bounds.minPrice} $</span>
                  {/* Left label */}
                  <span>{price[1] ? price[1] : bounds.maxPrice} $</span>
                  {/* Right label */}
                </div>
                <Slider
                  value={[
                    price[0] ? price[0] : bounds.minPrice,
                    price[1] ? price[1] : bounds.maxPrice,
                  ]}
                  onChange={handlePriceChange}
                  valueLabelDisplay="auto"
                  min={bounds.minPrice}
                  max={bounds.maxPrice}
                  style={{ color: "#d32f2f" }} // Custom color for the slider
                  valueLabelFormat={(value) => `${value} $`}
                />
              </div>
            </div>
          </div>
        ) : null}

        <div className={styles.filter}>
          <h3>ETD</h3>
          <div className={styles.inputs}>
            <DateInput
              theme={true}
              label={"from"}
              placeholderShowed={false}
              value={dateFields.etdFrom}
              handleChange={handleETDFrom}
              inputHeight={styles["date-height"]}
            />

            <DateInput
              theme={true}
              label={"to"}
              placeholderShowed={false}
              value={dateFields.etdTo}
              handleChange={handleETDTo}
              inputHeight={styles["date-height"]}
            />
          </div>
        </div>
        <div className={styles.filter}>
          <h3>ETA</h3>
          <div className={styles.inputs}>
            <DateInput
              theme={true}
              label={"from"}
              handleChange={handleETAFrom}
              value={dateFields.etaFrom}
              placeholderShowed={false}
              inputHeight={styles["date-height"]}
            />
            <DateInput
              theme={true}
              label={"to"}
              placeholderShowed={false}
              handleChange={handleETATo}
              value={dateFields.etaTo}
              inputHeight={styles["date-height"]}
            />
          </div>
        </div>
        <div className={styles.filter}>
          <h3>Transit Time</h3>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <span>
                {transit[0] ? transit[0] : bounds.minTransitTime} days
              </span>
              {/* Left label */}
              <span>
                {transit[1] ? transit[1] : bounds.maxTransitTime} days
              </span>{" "}
              {/* Right label */}
            </div>
            <Slider
              value={[
                transit[0] ? transit[0] : bounds.minTransitTime,
                transit[1] ? transit[1] : bounds.maxTransitTime,
              ]}
              onChange={handleTransitTimeChange}
              valueLabelDisplay="auto"
              min={bounds.minTransitTime}
              max={bounds.maxTransitTime}
              style={{ color: "#d32f2f" }} // Custom color for the slider
              valueLabelFormat={(value) => `${value} days`}
            />
          </div>
        </div>
        <div className={styles.filterButtons}>
          <Button
            variant="outlined"
            sx={{ textTransform: "capitalize" }}
            onClick={handleClearClick}
          >
            clear
          </Button>
          <Button
            variant="contained"
            sx={{ textTransform: "capitalize" }}
            onClick={handleApplyClick}
          >
            apply
          </Button>
        </div>
      </div>
    </aside>
  );
}
