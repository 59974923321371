import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import dashboardInactive from "../../assets/dashboard-inactive.svg";
import activeDashboard from "../../assets/dashboard-active.svg";
import requestsInactive from "../../assets/requests-inactive.svg";
import requestsActive from "../../assets/requests-active.svg";
import shipSchedulesInactive from "../../assets/ship-schedules-inactive.svg";
import shipSchedulesActive from "../../assets/ship-schedules-active.svg";
import shipmentsTrackingInactive from "../../assets/shipments-tracking-inactive.svg";
import shipmentsTrackingActive from "../../assets/shipments-tracking-active.svg";
import booking from "../../assets/booking-icon.svg";
import calculator from "../../assets/calculator.svg";
import activeCalculator from "../../assets/activeCalculator.svg";
import logout from "../../assets/logout.svg";
import { useNavigate, useLocation } from "react-router-dom";
import teuLogo from "../../assets/TEU-main-logo.svg";
import Close from "../../assets/Vector.png";
import profileIcon from "../../assets/profile-pic.png";
import avatarPlaceholder from "../../assets/user-placeholder.webp";

import creditIcon from "../../assets/credit.png";
import LogoutBtn from "../LogoutBtn";
import { setSidebarWidth } from "../../store/slices/sidebarSlice";
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../services/authService";
import { setProfile, userData } from "../../store/slices/userSlice";
import {
  getAllRequests,
  getSalesRequests,
} from "../../services/contentService";
import RemainingShipments from "../RemainingShipments";

function SideBar({ handleSideBar, handleOverlay, layout = "user" }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const sidebarRef = useRef(null);
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const { profile } = useSelector(userData);
  const [requests, setRequests] = useState(0);
  const [salesRequestsCount, setSalesRequestsCount] = useState(0);

  const activeRequestsCount = useSelector(
    (state) => state.sidebar.activeRequestsCount
  );
  const salesAllRequestsCount = useSelector(
    (state) => state.sidebar.salesRequestsCount
  );
  const fetchRequestsCount = async () => {
    const response = await getAllRequests(`page=1`, token);
    if (response.status === 200) {
      setRequests(response?.data?.count?.active);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };

  const fetchSalesRequestsCount = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `content/sales/requests`,
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    };
    const response = await getSalesRequests(config);

    if (response?.status === 200) {
      //console.log(response.data);
      setSalesRequestsCount(response.data.count);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };

  // Putting The Sidebar Width in The global State and 20 for the padding the sidebar.
  useEffect(() => {
    const updateSidebarWidth = () => {
      if (sidebarRef.current) {
        const width = sidebarRef.current.getBoundingClientRect().width;
        dispatch(setSidebarWidth(width));
      }
    };
    // Initial width calculation.
    updateSidebarWidth();

    // Update width on window resize.
    window.addEventListener("resize", updateSidebarWidth);

    // Cleanup on unmount.
    return () => window.removeEventListener("resize", updateSidebarWidth);
  }, [dispatch]);

  // Handling Window Resize.
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      //   handleSideBar(window.innerWidth > 1383);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Getting The Profile Data To Display The Avatar On The Header.
  const getProfileData = async () => {
    const response = await getProfile(token);
    if (response.status === 200) {
      dispatch(setProfile(response.data));
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  useEffect(() => {
    if (layout !== "sales") getProfileData();
  }, []);
  useEffect(() => {
    if (layout === "sales") {
      fetchSalesRequestsCount();
    } else {
      fetchRequestsCount();
    }
    // console.log(location.pathname);
    // console.log("client-request-count", requests);
    // console.log("salesRequestsCount", salesRequestsCount);
    // console.log("activeRequestsCount", activeRequestsCount);
    // console.log("salesAllRequestsCount", salesAllRequestsCount);
  }, [
    location.pathname,
    salesAllRequestsCount?.pending,
    salesAllRequestsCount?.accepted,
    activeRequestsCount,
  ]);

  const headerHeight = useSelector((state) => state.sidebar.headerHeight);

  return layout === "sales" ? (
    <>
      <aside
        className={`${styles["side-bar"]} ${styles["sales-side-bar"]}`}
        style={{ "--header-height": `${headerHeight}px` }}
        ref={sidebarRef}
      >
        <div className={styles.container}>
          <div className={styles.subContainer}>
            {isMobile ? (
              <>
                <div
                  style={{
                    display: "flex",
                    paddingTop: 5,
                    paddingBottom: 20,
                    paddingRight: 20,
                    paddingLeft: 20,
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div className={styles["subContainer-header-logo"]}>
                    <img style={{ width: 38, height: 38 }} src={teuLogo} />
                    <p>Sea Portal</p>
                  </div>
                  <button
                    onClick={() => {
                      if (isMobile) {
                        handleOverlay(false);
                        handleSideBar(false);
                      }
                    }}
                    style={{ background: "none", border: "none" }}
                  >
                    <img
                      style={{ width: 16, height: 16, marginRight: 10 }}
                      src={Close}
                    />
                  </button>
                </div>
                <RemainingShipments layout={layout} />
                <hr
                  style={{
                    marginBlockStart: 10,
                    marginBlockEnd: 5,
                    marginInline: 10,
                    border: "none",
                    border: "1px solid rgba(237, 237, 237, 1)",
                  }}
                />
                {/* <div
                  style={{
                    display: "flex",
                    padding: 10,
                    marginTop: 10,
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <button className={styles.profilePic}>
                    <img
                      style={{ marginRight: 10, height: 35, width: 35 }}
                      alt="profileIcon"
                      src={profileIcon}
                    />
                    <h3 style={{ opacity: 0.6, width: 15, color: "black" }}>
                      {" "}
                      {profile?.first_name} {profile?.last_name}{" "}
                    </h3>
                  </button>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: 12,
                      backgroundColor: "#F3F3F3",
                      width: 125,
                      height: 41,
                      justifyContent: "center",
                      paddingTop: 5,
                      paddingBottom: 5,
                      marginRight: 5,
                    }}
                  >
                    <button className={styles.credit}>
                      <img
                        style={{ marginRight: 5, height: 25, width: 25 }}
                        alt="credit"
                        src={creditIcon}
                      />
                    </button>
                    <p>
                      <text>5 credits</text>
                    </p>
                  </div>
                </div>
                <hr
                  style={{
                    marginTop: 25,
                    marginInline: 10,
                    border: "none",
                    border: "1px solid rgba(237, 237, 237, 1)",
                  }}
                /> */}
              </>
            ) : null}
            <div className={styles.group}>
              <ul className={styles["ul"]}>
                <li
                  className={
                    location.pathname === "/sales-requests"
                      ? styles.selectedLi
                      : styles.li
                  }
                >
                  <button
                    className={styles.logo}
                    onClick={() => {
                      navigate("/sales-requests");
                      if (isMobile) {
                        handleOverlay(false);
                        handleSideBar(false);
                      }
                    }}
                  >
                    <div className={styles.imgContainer}>
                      <img
                        className={styles.img}
                        src={
                          location.pathname === "/sales-requests"
                            ? requestsActive
                            : requestsInactive
                        }
                        alt="sideItem"
                      />
                    </div>
                    <p className={styles.text}>Requests</p>
                  </button>
                  {salesRequestsCount?.accepted + salesRequestsCount?.pending >
                    0 && (
                    <div className={styles["imgContainer-count"]}>
                      <p>
                        {salesRequestsCount?.accepted +
                          salesRequestsCount?.pending}
                      </p>
                    </div>
                  )}
                </li>
                <li
                  className={
                    location.pathname === "/shipment-tracking"
                      ? styles.selectedLi
                      : styles.li
                  }
                >
                  <button
                    className={styles.logo}
                    onClick={() => {
                      navigate("/shipment-tracking");
                      if (isMobile) {
                        handleOverlay(false);
                        handleSideBar(false);
                      }
                    }}
                  >
                    <div className={styles.imgContainer}>
                      <img
                        className={styles.img}
                        src={
                          location.pathname === "/sales-shipment-tracking"
                            ? shipmentsTrackingActive
                            : shipmentsTrackingInactive
                        }
                        alt="shipment"
                      />
                    </div>
                    <p className={styles.text}>Shipments tracking</p>
                  </button>
                </li>
              </ul>
            </div>
            {isMobile && (
              <div className={`${styles.group} ${styles["logout-group"]}`}>
                <ul className={styles["ul"]}>
                  <li className={styles.li}>
                    <button className={styles.logo}>
                      {/* <img className={styles.img} src={logout} alt="sideItem" /> */}
                      <p className={styles.text}>
                        <LogoutBtn />
                      </p>
                    </button>
                  </li>
                </ul>
              </div>
            )}
            {layout === "sales" && !isMobile && (
              <div
                className={`${styles.group} ${styles["logout-group"]} ${styles["logout-sales"]}`}
              >
                <ul className={styles["ul"]}>
                  <li className={styles.li}>
                    <button className={styles.logo}>
                      {/* <img className={styles.img} src={logout} alt="sideItem" /> */}
                      <p className={styles.text}>
                        <LogoutBtn />
                      </p>
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </aside>
    </>
  ) : (
    <>
      <aside
        className={styles["side-bar"]}
        style={{ "--header-height": `${headerHeight}px` }}
        ref={sidebarRef}
      >
        <div className={styles.container}>
          <div className={styles.subContainer}>
            {isMobile ? (
              <>
                <div className={styles["subContainer-header"]}>
                  <div className={styles["subContainer-header-logo"]}>
                    <img style={{ width: 38, height: 38 }} src={teuLogo} />
                    <p>Sea Portal</p>
                  </div>
                  <button
                    onClick={() => {
                      if (isMobile) {
                        handleOverlay(false);
                        handleSideBar(false);
                      }
                    }}
                    style={{
                      background: "none",
                      border: "none",
                      display: "flex",
                    }}
                  >
                    <img
                      style={{
                        width: 16,
                        height: 16,
                        marginRight: 10,
                        cursor: "pointer",
                      }}
                      src={Close}
                    />
                  </button>
                </div>
                <div className={styles["subContainer-info"]}>
                  <button
                    className={styles.profilePic}
                    onClick={() => {
                      navigate("/profile");
                      if (isMobile) {
                        handleOverlay(false);
                        handleSideBar(false);
                      }
                    }}
                  >
                    <img
                      style={{
                        marginRight: 10,
                        height: 40,
                        width: 40,
                        borderRadius: "50%",
                        outline: "4.55px solid #f4f4f4",
                      }}
                      alt="profile-icon"
                      src={profile?.picture ?? avatarPlaceholder}
                    />
                    <h3 style={{ opacity: 0.6, textAlign: "left" }}>
                      {profile.first_name} {profile.last_name}
                    </h3>
                  </button>
                  {/* <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      borderRadius: 12,
                      backgroundColor: "#F3F3F3",
                      width: 125,
                      height: 41,
                      justifyContent: "center",
                      paddingTop: 5,
                      paddingBottom: 5,
                      marginRight: 5,
                    }}
                  >
                    <button className={styles.credit}>
                      <img
                        style={{ marginRight: 5, height: 25, width: 25 }}
                        alt="credit"
                        src={creditIcon}
                      />
                    </button>
                    <p>
                      <text>5 credits</text>
                    </p>
                  </div> */}
                </div>
                <hr className={styles["subContainer-hr"]} />
              </>
            ) : null}
            <div className={styles.group}>
              <ul className={styles["ul"]}>
                <li
                  className={
                    location.pathname === "/dashboard"
                      ? styles.selectedLi
                      : styles.li
                  }
                >
                  <button
                    className={styles.logo}
                    onClick={() => {
                      navigate("/dashboard");
                      if (isMobile) {
                        handleOverlay(false);
                        handleSideBar(false);
                      }
                    }}
                  >
                    <div className={styles.imgContainer}>
                      <img
                        className={styles.img}
                        src={
                          location.pathname === "/dashboard"
                            ? activeDashboard
                            : dashboardInactive
                        }
                        alt="sideItem"
                      />
                    </div>
                    <p className={styles.text}>Dashboard</p>
                  </button>
                </li>
                <li
                  className={
                    location.pathname === "/requests"
                      ? styles.selectedLi
                      : styles.li
                  }
                >
                  <button
                    className={styles.logo}
                    onClick={() => {
                      navigate("/requests");
                      if (isMobile) {
                        handleOverlay(false);
                        handleSideBar(false);
                      }
                    }}
                  >
                    <div className={styles.imgContainer}>
                      <img
                        className={styles.img}
                        src={
                          location.pathname === "/requests"
                            ? requestsActive
                            : requestsInactive
                        }
                        alt="sideItem"
                      />
                    </div>
                    <p className={styles.text}>Requests</p>
                  </button>
                  {requests > 0 ? (
                    <div className={styles["imgContainer-count"]}>
                      <p>{requests}</p>
                    </div>
                  ) : null}
                </li>
              </ul>
            </div>
            <div className={styles.group}>
              <div className={styles.title}>
                <h4
                  style={{
                    fontWeight: "400",
                  }}
                >
                  Services
                </h4>
              </div>

              <ul className={styles["ul"]}>
                <li
                  className={
                    location.pathname === "/" ? styles.selectedLi : styles.li
                  }
                >
                  <button
                    className={styles.logo}
                    onClick={() => {
                      navigate("/");
                      if (isMobile) {
                        handleOverlay(false);
                        handleSideBar(false);
                      }
                    }}
                  >
                    <div className={styles.imgContainer}>
                      <img
                        className={styles.img}
                        src={
                          location.pathname === "/"
                            ? shipSchedulesActive
                            : shipSchedulesInactive
                        }
                        alt="searchVessels"
                      />
                    </div>
                    <p className={styles.text}>Ship Schedules</p>
                  </button>
                </li>
                {/* Shipment Tracking Navigation Needs to be handled after the page is done */}
                <li
                  className={
                    location.pathname === "/shipment-tracking"
                      ? styles.selectedLi
                      : styles.li
                  }
                >
                  <button
                    className={styles.logo}
                    onClick={() => {
                      navigate("/shipment-tracking");
                      if (isMobile) {
                        handleOverlay(false);
                        handleSideBar(false);
                      }
                    }}
                  >
                    <div className={styles.imgContainer}>
                      <img
                        className={styles.img}
                        src={
                          location.pathname === "/shipment-tracking"
                            ? shipmentsTrackingActive
                            : shipmentsTrackingInactive
                        }
                        alt="shipment"
                      />
                    </div>
                    <p className={styles.text}>Shipments tracking</p>
                  </button>
                </li>
                {/* Load Calculator Commented For Now */}
                {/* <li
                  className={
                    location.pathname === "/Load Calculating"
                      ? styles.selectedLi
                      : styles.li
                  }
                >
                  <button
                    className={styles.logo}
                    onClick={() => {
                      //console.log("Load Calculating Page");
                      if (isMobile) {
                        handleOverlay(false);
                        handleSideBar(false);
                      }
                    }}
                  >
                    <div
                      className={
                        location.pathname === "/load-calculating"
                          ? `${styles.imgContainer} ${styles["imgContainer-active"]}`
                          : styles.imgContainer
                      }
                    >
                      <img
                        className={styles.img}
                        src={
                          location.pathname === "/load-calculating"
                            ? activeCalculator
                            : calculator
                        }
                        alt="searchVessels"
                      />
                    </div>
                    <p className={styles.text}>Load Calculator</p>
                  </button>
                </li> */}
              </ul>
            </div>

            {isMobile && (
              <div className={`${styles.group} ${styles["logout-group"]}`}>
                <ul className={styles["ul"]}>
                  <li className={styles.li}>
                    <button className={styles.logo}>
                      {/* <img className={styles.img} src={logout} alt="sideItem" /> */}
                      <p className={styles.text}>
                        <LogoutBtn />
                      </p>
                    </button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </aside>
    </>
  );
}

export default SideBar;
