import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import RequestCardUpdated from "../../components/requestCardUpdated";
import BackArrow from "../../assets/BackArrowIcon.svg";
import ShipmentDeleted from "../../assets/shipment-deleted.svg";
import Map from "../../assets/Mapsicle.png";
import HorizontalTabs from "../../components/HorizontalTabs";
import ClientDetails from "../../components/ClientDetails";
import Button from "../../assets/Button.svg";
import phone from "../../assets/Vector.svg";
import mail from "../../assets/mail.svg";
import EditConfirmationModal from "../../components/EditConfirmationModal";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import BaseModal from "../../components/BaseModal";
import { useLocation, useNavigate } from "react-router-dom";
import MainButton from "../../components/mainButton";
import ShipmentForm from "../../components/ShipmentForm";
import DashboardTimeline from "../../components/DashboardTimeline";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ShipmentModal from "../../components/ShipmentModal";
import Carousel from "../../components/carousel";
import moment from "moment";
import shipmentInfoShip from "../../assets/shipmentInfoShip.png";
import {
  deleteShipment,
  getRequestInfo,
  getShipments,
} from "../../services/contentService";
import { set } from "react-hook-form";
import DashboardTimelineSkeleton from "../../components/DashboardTimeLineSkeleton";
import ShipmentTrackingMap from "../../components/Map";
import ContainersAndEvents from "../../components/ContainersAndEvents";
import axios from "axios";

function ShipmentInfo() {
  const type = localStorage.getItem("type");
  const location = useLocation();
  const { trackingNumber } = location.state || {};
  const [filters, setFilters] = useState({});
  const [activeTab, setActiveTab] = useState("Containers & Events");
  const [loading, setLoading] = useState(false);
  const user = localStorage.getItem("type");
  const token = localStorage.getItem("token");
  const [edit, setEdit] = useState(null);
  const [open, setOpen] = useState(false);
  const [requestInfo, setRequestInfo] = useState([]);
  const [showSendOfferModal, setShowSendOfferModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();
  const [openDropdownIndex, setOpenDropdownIndex] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setOpenDropdownIndex(!openDropdownIndex);
    console.log(openDropdownIndex);
  };

  // Close dropdown when clicking outside

  const request = {
    id: 6885943,
    ETD: "2024-09-05",
    ETA: "2024-09-25",
    containers: [
      {
        name: "20 Dry Standard",
        type: "20DS",
        number: 1,
        weight: 1000.0,
        request: 6885943,
      },
      {
        name: "40 Dry Standard",
        type: "40DS",
        number: 2,
        weight: 1000.0,
        request: 6885943,
      },
      {
        name: "40 Dry High Cube",
        type: "40DHC",
        number: 3,
        weight: 1100.0,
        request: 6885943,
      },
      {
        name: "40 Reefer High Cube",
        type: "40RHC",
        number: 4,
        weight: 1200.0,
        request: 6885943,
      },
    ],
    expired: false,
    client_fname: "Mohamed",
    client_lname: "Abdulaziz",
    client_company: "uniparticle",
    client_tier: "premium",
    client_email: "mohamedaboelfotouh414@gmail.com",
    client_phone: "01025470228",
    client_picture:
      "https://teu.uniparticle.com/media/Screenshot_2024-10-22_154708_Kx4yleg.webp",
    client_position: "frontend",
    origin_address: "Origin Address",
    origin_state: "Origin State",
    destination_address: "Destination Address",
    destination_state: "Destination State",
    pod_free_time: 45,
    target_rate: 2500,
    readiness_date: "2024-10-31",
    commodity: "Commodity",
    invoice_value: 500,
    dangerous_cargo: true,
    un_number: null,
    class_number: null,
    additional_info:
      "Target rate is your expected overall pricing in US dollars. Also, enter any additional information we should consider when preparing the quotation for you.",
    request_status: "pending",
    user_status: "pending",
    attachment: null,
    expiration_date: "2025-10-20T10:01:15.055177+03:00",
    created_at: "2024-10-20T13:37:06.866683+03:00",
    updated_at: "2024-10-20T14:33:45.423105+03:00",
    freight_price: 0.0,
    marked: false,
    seen: true,
    user: 42,
    sales_person: 35,
    origin_country: "Gambia",
    destination_country: "Cambodia",
    shipping_line: {
      id: 27,
      name: "CK Line",
      scac: "CKLU",
    },
    port_of_loading: "Puerto Cortes",
    port_of_discharge: "Anchorage",
    required_services: [
      {
        id: 2,
        title: "POL Trucking",
      },
      {
        id: 4,
        title: "POD Trucking",
      },
      {
        id: 6,
        title: "Insurance",
      },
    ],
    origin_country_flag:
      "https://hatscripts.github.io/circle-flags/flags/gm.svg",
    destination_country_flag:
      "https://hatscripts.github.io/circle-flags/flags/kh.svg",
    pol_country_name: "Honduras",
    pol_country_flag: "https://hatscripts.github.io/circle-flags/flags/hn.svg",
    pod_country_name: "United States",
    pod_country_flag: "https://hatscripts.github.io/circle-flags/flags/us.svg",
  };
  const {
    id,
    created_at,
    request_status,
    origin_state,
    origin_country,
    pol_country_flag,
    pol_country_name,
    seen,
    pod_country_flag,
    pod_country_name,
    port_of_loading,
    port_of_discharge,
    required_services,
    invoice_value,
    destination_country,
    destination_state,
    readiness_date,
    shipping_line,
    commodity,
    containers,
    origin_address,
    destination_address,
    pod_free_time,
    target_rate,
    additional_info,
    expiration_date,
    attachment,
    dangerous_cargo,
  } = request;

  const salesTabs = [
    {
      name: "Containers & Events",
      slug: "Containers & Events",
    },
    {
      name: "Client",
      slug: "Client",
    },
  ];
  const clientTabs = [
    {
      name: "Containers & Events",
      slug: "Containers & Events",
    },
  ];
  const fetchShipmentDetails = async (trackingNumber) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `shipment/tracking/${user}/list?id=${trackingNumber}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    setLoading(true);
    const response = await getShipments(config);

    if (response?.status === 200) {
      setRequestInfo(response?.data?.data);
      setFilters(response?.data?.filters);

      setLoading(false);
    } else if (response.status === 401) {
      setLoading(false);
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  const handleDeleteShipment = async () => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `shipment/tracking/delete/${requestInfo[0]?.id}`,
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    };
    const response = await deleteShipment(config);
    if (response?.status === 200) {
      console.log("deleteShipment");
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  useEffect(() => {
    if (trackingNumber) {
      fetchShipmentDetails(trackingNumber);
    }
  }, [trackingNumber]);
  const handleClose = () => {
    setShowSuccessModal(false);
    navigate("/shipment-tracking");
  };
  const handleSuccess = () => {
    setShowSuccessModal(false);
    navigate("/shipment-tracking");
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownIndex(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // console.log(
  //   filters?.tracked_by?.find(
  //     (option) => option.key === requestInfo[0]?.tracking_type
  //   )
  // );
  // console.log(requestInfo[0]);
  // console.log(requestInfo[0]?.tracking_type);
  // console.log(filters?.tracked_by);
  return (
    <>
      {true ? (
        <div className={styles["shipment-info"]}>
          <div className={styles["shipment-info-container"]}>
            <div className={styles["shipment-info-description"]}>
              <div className={styles["shipment-info-title"]}>
                <div className={styles["shipment-info-title-text"]}>
                  <img
                    onClick={() => navigate(-1)}
                    src={BackArrow}
                    alt="Back"
                  />
                  <p>Shipment Tracking</p>
                </div>
                {type === "sales" && (
                  <div className={styles.filterContainer} ref={dropdownRef}>
                    <button
                      className={styles.filterButton}
                      onClick={() => toggleDropdown()}
                    >
                      <img src={Button} />
                    </button>
                    {openDropdownIndex && (
                      <div className={styles["filterContainer-dropdownMenu"]}>
                        <div
                          className={styles["filterContainer-dropdownItem"]}
                          onClick={() => setShowSendOfferModal(true)}
                        >
                          Edit Shipment
                        </div>

                        <div
                          onClick={() => setOpen(true)}
                          className={styles["filterContainer-dropdownItem"]}
                        >
                          Delete Shipment
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {/* The Timeline */}
              <div
                style={{
                  border: "1px solid #E2E3E3",
                  borderRadius: "0.6rem",
                }}
              >
                {loading ? (
                  <DashboardTimelineSkeleton />
                ) : (
                  <DashboardTimeline lastestShipment={requestInfo[0] ?? []} />
                )}
              </div>

              <div>
                <HorizontalTabs
                  tabs={type === "sales" ? salesTabs : clientTabs}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />

                {/* The Client Details Tab Information */}
                {activeTab == "Client" && (
                  <ClientDetailsCard requestInfo={requestInfo} />
                )}
                {/* The Containers & Events Tab Information */}
                {activeTab === "Containers & Events" &&
                  (requestInfo[0]?.searates_body ? (
                    <ContainersAndEvents shipmentInfo={requestInfo[0]} />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "36px",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className={styles["shipment-info-map"]}>
            {requestInfo[0]?.searates_body ? (
              <ShipmentTrackingMap lastestShipment={requestInfo[0]} />
            ) : (
              <div>Loading...</div>
            )}
          </div>
          {isMobile ? (
            <ShipmentModal
              setShowSendOfferModal={setShowSendOfferModal}
              request={request}
              dropdownRef={dropdownRef}
              toggleDropdown={toggleDropdown}
              openDropdownIndex={openDropdownIndex}
              setOpen={setOpen}
            >
              <div
                style={{
                  // border: "1px solid #E2E3E3",
                  borderRadius: "0.6rem",
                  marginInline: "1rem",
                  // padding: "1rem",
                  marginBottom: "2rem",
                }}
              >
                {loading ? (
                  <DashboardTimelineSkeleton />
                ) : (
                  <DashboardTimeline lastestShipment={requestInfo[0] ?? []} />
                )}
              </div>
              <div
                style={{
                  // border: "1px solid #E2E3E3",
                  borderRadius: "0.6rem",
                  marginInline: "1rem",
                  // padding: "1rem",
                }}
              >
                <Carousel usel carouselArrows={styles.carouselArrows}>
                  {/* Containers & Events Slide */}
                  <div className={styles["card-collapse-step"]}>
                    <h3>Containers & Events</h3>
                    <div>
                      {requestInfo[0]?.searates_body ? (
                        <ContainersAndEvents shipmentInfo={requestInfo[0]} />
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "36px",
                          }}
                        >
                          <CircularProgress />
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Client Details */}
                  {type === "sales" && (
                    <div className={styles["card-collapse-step"]}>
                      <h3>Client Details</h3>
                      <div>
                        <ClientDetailsCard requestInfo={requestInfo} />
                      </div>
                    </div>
                  )}
                </Carousel>
              </div>
            </ShipmentModal>
          ) : null}
          {/* Modals */}
          <DeleteConfirmationModal
            open={open}
            setOpen={setOpen}
            setSuccess={setShowSuccessModal}
            confirm={handleDeleteShipment}
          />
          {/* Edit Shipment Modal */}
          <BaseModal
            title={"Edit Shipment"}
            isOpen={showSendOfferModal}
            handleClose={() => setShowSendOfferModal(false)}
          >
            <ShipmentForm
              setShowSuccessModal={setShowSuccessModal}
              setShowSendOfferModal={setShowSendOfferModal}
              errorMessage={"something went wrong please try again later "}
              TN={requestInfo[0]?.tracking_number}
              NT={filters?.tracked_by?.find(
                (option) => option.key === requestInfo[0]?.tracking_type
              )}
              CE={requestInfo[0]?.client_email}
              CM={requestInfo[0]?.commodity}
              SL={requestInfo[0]?.shipping_line_name}
              filters={filters}
              action={"edit"}
              id={requestInfo[0]?.id}
              fetchShipmentDetails={fetchShipmentDetails}
            />
          </BaseModal>
          {/* Success Modal */}
          <BaseModal
            title={"Request & Client Details"}
            isOpen={showSuccessModal}
            handleClose={handleClose}
            isTitle={false}
          >
            <div className={styles["shipment-info-success-modal"]}>
              <img src={ShipmentDeleted} />
              <h3 className={styles["shipment-info-h3"]}>
                Shipment Successfully Deleted{" "}
              </h3>

              <div className={styles["shipment-info-button-success"]}>
                <MainButton
                  className={styles["shipment-info-button-success-btn"]}
                  handleSubmit={handleSuccess}
                >
                  Done
                </MainButton>
              </div>
            </div>
          </BaseModal>
        </div>
      ) : null}
    </>
  );
}

const ClientDetailsCard = ({ requestInfo }) => {
  const baseURL = axios.defaults.baseURL;
  return (
    <div className={styles.clientDetailsCard}>
      <h3>Client Details</h3>
      <div className={styles.card}>
        <div className={styles.clientInfo}>
          <img
            className={styles.avatar}
            src={
              requestInfo[0]?.client_pic
                ? `${baseURL}${requestInfo[0]?.client_pic}`
                : "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"
            }
            alt=""
          />
          <div className={styles.nameAndPosition}>
            <h4>{requestInfo[0]?.client}</h4>
            <p>
              {requestInfo[0]?.client_company
                ? requestInfo[0]?.client_company
                : ""}{" "}
              {requestInfo[0]?.client_position
                ? " - " + requestInfo[0]?.client_position
                : "Front-end Developer"}
            </p>
          </div>
        </div>
        <div className={styles.contactInfo}>
          <div className={styles.contactItem}>
            <img src={mail} alt="Email Icon" />
            <p>
              {requestInfo[0]?.client_email
                ? requestInfo[0]?.client_email
                : "anything@anything.com"}
            </p>
          </div>
          <div className={styles.contactItem}>
            <img src={phone} alt="Phone Icon" />
            <p>
              {requestInfo[0]?.client_phone
                ? requestInfo[0]?.client_phone
                : "01234567891"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentInfo;
