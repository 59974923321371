import {
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import styles from "./index.module.scss";
import minusButton from "../../assets/minus-button.svg";
import plusButton from "../../assets/plus-button.svg";
import icon from "../../assets/grey-chevron-down.svg";
import { useLocation } from "react-router-dom";

const DropdownMultipleWithCount = ({
  inputIcon = icon,
  label,
  value,
  onChange,
  placeholder,
  options,
  handleCount,
  containerStyle,
  inputStyle,
  labelStyle,
  listItemTextStyle,
  className = "",
  valueKey = "type",
  customPlaceholderColor = "",
  handleChange,
  background,
}) => {
  const location = useLocation();
  const pathname = location.pathname;
  const dropdownContainerStyle = {
    // width: "100%",
    minHeight: "2.6rem",
    background: "#fff",
    borderRadius: "6px",
    padding: "0px !important",
    outline: "none !important",
    fontSize: "0.875rem !important",
    marginTop: "2px !important",
    ...containerStyle,
    // borderColor: trigger && !selectedGrade ? "#1F1F21" : "#1F1F21",
    "& *": {
      fontFamily: "Poppins !important",
      fontSize: "0.875rem !important",
      border: "0 !important",
      // color: "#1F1F21 !important",
      // paddingTop: "0px !important",
      // paddingBottom: "0px !important",
    },
    "&-MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
      backgroundColor: "transparent !important",
      "&:hover": {
        backgroundColor: "transparent !important",
      },
    },
    "& .MuiOutlinedInput-input": {
      color: value?.length > 0 ? "inherit" : "#bababa",
      paddingBlock: "12px !important",
      fontSize: "0.875rem !important",
      ...inputStyle,
    },
  };

  const menuItemStyle = {
    fontFamily: "Poppins !important",
    fontSize: "0.875rem !important",
    border: "0 !important",
  };

  const labelStyles = {
    fontSize: "1rem",
    fontWeight: 400,
    fontFamily: "Poppins",
    color: "#000",
    marginBottom: "0.5rem!important",
    padding: "0 !important",
    ...labelStyle,
  };

  // Define MenuProps for custom height
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250, // Set max height for dropdown options
        overflowY: "auto",
      },
    },
  };

  return (
    <div
      className={`${
        pathname === "/" ? styles["container-freight"] : styles["container"]
      } ${className}`}
    >
      <InputLabel style={labelStyles} id="demo-multiple-chip-label-grade">
        {label}
      </InputLabel>
      <Select
        IconComponent={() => (
          <img src={inputIcon} alt="" className={styles["icon"]} />
        )}
        multiple
        label={label}
        labelId="demo-multiple-chip-label-grade"
        id="demo-multiple-chip-grade"
        sx={dropdownContainerStyle}
        displayEmpty
        value={value}
        onChange={onChange}
        MenuProps={MenuProps} // Apply MenuProps here
        renderValue={(selected) =>
          selected?.length > 0 ? (
            selected
              .map((item, i) => `${item?.name} (x${value[i]?.count})`)
              .join(", ")
          ) : (
            <span className={customPlaceholderColor}>{placeholder}</span>
          )
        }
      >
        {options?.map((item) => (
          <MenuItem key={item[valueKey]} value={item} sx={menuItemStyle}>
            <div className={styles["menuItemContainer"]}>
              <div className={styles["menuItemContainer-label"]}>
                <Checkbox
                  checked={
                    !!value?.find(
                      (option) => option?.[valueKey] == item?.[valueKey]
                    )
                  }
                  icon={
                    <span
                      style={{
                        display: "inline-block",
                        width: 20,
                        height: 20,
                        borderRadius: "50%",
                        border: "2px solid #98a2b3",
                      }}
                    ></span>
                  }
                  checkedIcon={
                    <span
                      style={{
                        width: 20,
                        height: 20,
                        borderRadius: "50%",
                        backgroundColor: "#fff",
                        border: "2px solid #98a2b3",
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          position: "absolute",
                          // top: "2.5px",
                          // left: "2.5px",
                          width: 10,
                          height: 10,
                          borderRadius: "50%",
                          backgroundColor: "#157ffb",
                        }}
                      ></span>
                    </span>
                  }
                  sx={{
                    "& .MuiSvgIcon-root": { display: "none" }, // Hide the default checkbox icon
                  }}
                />
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "0.875rem",
                    ...listItemTextStyle,
                  }}
                  primary={item.name}
                />
              </div>
              <>
                {item[valueKey] !== "LCL" && (
                  <>
                    {!!value?.find(
                      (option) => option?.[valueKey] == item?.[valueKey]
                    ) && (
                      <div className={styles["menuItemContainer-count"]}>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCount(item[valueKey], "-");
                          }}
                        >
                          <img src={minusButton} />
                        </button>
                        <span>
                          {
                            value?.find(
                              (option) => option[valueKey] == item[valueKey]
                            )?.count
                          }
                        </span>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleCount(item[valueKey], "+");
                          }}
                        >
                          <img src={plusButton} />
                        </button>
                      </div>
                    )}
                  </>
                )}
              </>
            </div>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default DropdownMultipleWithCount;
