// CustomModal.jsx

import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "../../assets/Vector.png";
import styles from "./index.module.scss";
import DateInput from "../dateInput";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
function SortModal({
  handleOpen,
  isOpen,
  handleSort,
  sortCriterion,
  shouldShowPrice,
}) {
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState("");
  const handleClose = () => {
    handleOpen(false);
  };

  const handleChange = (newValue) => {
    setValue(newValue.target.value);
    handleSort(newValue.target.value);
    handleClose();
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="xl" // Adjust width as needed
      fullWidth
      PaperProps={{
        className: styles.customDialogPaper,
      }}
      classes={{ paper: styles.customDialogRoot }}
    >
      <DialogTitle>
        <div className={styles.titleBar}>
          <span>Sort by</span>
          <IconButton
            onClick={handleClose}
            size="small"
            className={styles.closeButton}
          >
            <img src={CloseIcon} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={styles.container}>
          {shouldShowPrice && (
            <div className={styles.radioItem}>
              <input
                type="radio"
                id="lowestPrice"
                name="lowestPrice"
                value="lowestPrice"
                checked={sortCriterion === "lowestPrice"}
                onChange={handleChange}
                className={styles.radioInput}
              />
              <label className={styles.radioLabel}>Price</label>
            </div>
          )}
          <div className={styles.radioItem}>
            <input
              type="radio"
              id="departure"
              name="departure"
              value="departure"
              checked={sortCriterion === "departure"}
              onChange={handleChange}
              className={styles.radioInput}
            />
            <label className={styles.radioLabel}>Departure</label>
          </div>
          <div className={styles.radioItem}>
            <input
              type="radio"
              id="soonentArrival"
              name="soonentArrival"
              value="soonentArrival"
              checked={sortCriterion === "soonentArrival"}
              onChange={handleChange}
              className={styles.radioInput}
            />
            <label className={styles.radioLabel}>Arrival</label>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default SortModal;
