import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Upload from "../../assets/upload.svg";
import X from "../../assets/x.svg";
import { useDroppable } from "@dnd-kit/core";
import { DndContext } from "@dnd-kit/core";
import DateInput from "../dateInput";
import MainButton from "../mainButton";
import { selectOpenedRequestID } from "../../store/slices/salesRequestsSlice";
import { useSelector } from "react-redux";
import {
  createShipment,
  getRequestQuoteData,
  sendOffer,
} from "../../services/contentService";
import DropdownWithCheckboxSelect from "../DropdownWithCheckboxSelect.jsx";
import { set } from "react-hook-form";
import { FormInput } from "../formInput/index.jsx";
import { useHookForm } from "../../hooks/hook-form.js";
import RequestQuoteInput from "../requestQuoteForm/input/index.jsx";
import CargoDropdown from "../CargoDropdown/index.jsx";
import DropdownWithRadioButtonSelect from "../DropdownWithRadioButtonSelect/index.jsx";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/index.jsx";
import Dropdown from "../dropdown/index.jsx";
const ShipmentForm = ({
  setShowSendOfferModal,
  setShowSuccessModal,
  NT = null,
  SL = null,
  TN = null,
  CE = null,
  CM = null,
  filters = {},
  action = "create",
  id = null,
  fetchShipmentDetails = () => null,
}) => {
  const [showError, setShowError] = useState(false);
  const [addShipmentLoading, setAddShipmentLoading] = useState(false);
  const navigate = useNavigate();

  const [numberTypes, setNumberTypes] = useState(NT);

  const [shippingLines, setShippingLines] = useState([]);
  const [selectedShippingLine, setSelectedShippingLine] = useState(SL);
  const token = localStorage.getItem("token");
  const {
    handleSubmit,
    errors,
    isDirty,
    isValid,
    emailRegister,
    commodityRegister,
    trackingNumberRegister,
    requiredPasswordRegister,
    watch,
    setValue,
    dirtyFields,
    getValues,
  } = useHookForm({
    isEmail: true,
    isCommodity: true,
    isTrackingNumber: true,
  });

  const shipmentFormValues = getValues();

  const handleShipmentCreate = async (Data) => {
    setAddShipmentLoading(true);
    let data = JSON.stringify({
      client_email: Data?.email,
      tracking_number: Data?.trackingNumber,
      tracking_type: numberTypes?.key,
      commodity: Data?.commodity,
      scac: selectedShippingLine?.shippingLine?.scac,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "shipment/tracking/create",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    try {
      const response = await createShipment(config);

      if (response.status === 201 || response.status === 200) {
        setShowSendOfferModal(false);
        setShowSuccessModal(true);
      } else if (response.status === 401) {
        navigate("/login", { replace: true });
        localStorage.removeItem("token");
      } else {
        setShowError(true); // For other non-2xx status codes
      }
    } catch (error) {
      console.error("Shipment creation failed:", error);
      setShowError(true);
    } finally {
      setAddShipmentLoading(false);
    }
  };
  const handleShipmentEdit = async (Data) => {
    // event.preventDefault();
    let data = JSON.stringify({
      client_email: Data?.email,
      tracking_number: Data?.trackingNumber,
      tracking_type: numberTypes?.key,
      commodity: Data?.commodity,
      scac: selectedShippingLine?.shippingLine?.scac,
    });

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `shipment/tracking/edit/${id}`,
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    const response = await createShipment(config);
    if (response.status === 200) {
      setShowSendOfferModal(false);
      fetchShipmentDetails(shipmentFormValues.trackingNumber);
      // setShowSuccessModal(true);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    } else {
      setShowError(true);
    }
  };
  const handleDateFormat = (dateString) => {
    const date = new Date(dateString);

    if (isNaN(date)) {
      throw new Error("Invalid date string");
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const handleShippingLineChange = (event) => {
    const {
      target: { value },
    } = event;
    const selectOption = shippingLines.find((option) => option.name === value);
    setSelectedShippingLine({
      ...selectedShippingLine,
      shippingLine: selectOption,
    });
  };

  const getInputsData = async () => {
    try {
      const response = await getRequestQuoteData();
      if (response.status === 200) {
        setShippingLines(response.data.shipping_lines);
      }
    } catch (err) {
      //console.log("err", err);
    }
  };
  useEffect(() => {
    getInputsData();
  }, []);

  const shippingLineContainerStyle = {
    height: "2.875rem",
    border: "1px solid rgb(238, 238, 238)",
    borderRadius: "0.5rem",
  };
  const shippingLineLabelStyle = {
    color: "#333",
    fontWeight: "500",
    fontSize: "1rem",
  };

  // console.log("shipmentFormValues", shipmentFormValues);

  return (
    <form
      className={styles.formContainer}
      onSubmit={handleSubmit(
        action === "create" ? handleShipmentCreate : handleShipmentEdit
      )}
    >
      {showError && (
        <div className={styles.errorContainer}>
          <div className={styles.errorIcon}>
            <span>!</span>
          </div>
          <p>
            An error has occurred. Please review the information entered and try
            again.
          </p>
          <button
            onClick={() => setShowError(false)}
            className={styles.closeButton}
          >
            &#10006;
          </button>
        </div>
      )}
      <div className={styles.row}>
        <div className={styles.formGroup}>
          <p className={styles.formLabel}>Tracking Number*</p>
          <FormInput
            name="trackingNumber"
            error={errors["trackingNumber"]}
            placeholder={"Enter tracking number"}
            formRegister={trackingNumberRegister}
            type={"number"}
            labelIcon={false}
            value={shipmentFormValues.trackingNumber ?? TN}
          />
        </div>
        <div className={styles.formGroup}>
          <DropdownWithRadioButtonSelect
            label={"Number Type*"}
            value={numberTypes?.title ?? null}
            placeholder={"Select number type"}
            options={filters?.tracked_by}
            selectedValue={numberTypes?.title ?? null}
            setSelectedValue={setNumberTypes}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.formGroup}>
          {/* <DropdownWithRadioButtonSelect
            options={filters.shipping_line}
            label={"Shipping Line*"}
            placeholder={"Choose shipping line"}
            selectedValue={shippingLine?.title ?? null}
            setSelectedValue={setShippingLine}
            className={styles.dropdown}
          /> */}
          <Dropdown
            label={"Shipping Line*"}
            value={
              selectedShippingLine?.shippingLine?.name ?? selectedShippingLine
            }
            onChange={handleShippingLineChange}
            placeholder={"Select shipping line"}
            placeholderShowed={true}
            options={shippingLines}
            className={styles.dropdown}
            containerStyle={shippingLineContainerStyle}
            labelStyle={shippingLineLabelStyle}
          />
        </div>
        <div className={styles.formGroup}>
          <p className={styles.formLabel}>Registered Client Email*</p>
          <FormInput
            name="email"
            error={errors["email"]}
            placeholder={"Email"}
            value={shipmentFormValues.email ?? CE}
            formRegister={emailRegister}
            labelIcon={false}
            labelIconName={"Email"}
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.formGroup}>
          <p className={styles.formLabel}>Commodity*</p>
          <FormInput
            placeholder={"Enter the commodity"}
            name={"commodity"}
            error={errors["commodity"]}
            formRegister={commodityRegister}
            value={shipmentFormValues.commodity ?? CM}
          />
        </div>
      </div>

      <div className={styles["formContainer-button"]}>
        {addShipmentLoading ? (
          <LoadingSpinner />
        ) : (
          <MainButton
            className={styles["formContainer-button-btn"]}
            type="submit"
            disabled={
              !isValid ||
              !isDirty ||
              numberTypes === null ||
              selectedShippingLine === null
            }
          >
            Add
          </MainButton>
        )}
      </div>
    </form>
  );
};

export default ShipmentForm;
