import React, { act, useEffect, useLayoutEffect, useState } from "react";
import styles from "./index.module.scss";
import HorizontalTabs from "../../components/HorizontalTabs";
import ArrowTab from "../../components/SVGs/ArrowTab";
import ToggleTab from "../../components/SVGs/ToggleTab";
import ClockTab from "../../components/SVGs/ClockTab";
import TrueTab from "../../components/SVGs/TrueTab";
import tick from "../../assets/Select.svg";
import ExpireTab from "../../components/SVGs/ExpireTab";
import OfferSuccess from "../../assets/shipmentIcon.svg";
import Actions from "../../components/Actions";
import RequestsTable from "../../components/RequestsTable";
import Pagination from "../../components/Pagination";
import BaseModal from "../../components/BaseModal";
import ClientDetails from "../../components/ClientDetails";
import RequestDetails from "../../components/RequestDetails";
import MainButton from "../../components/mainButton";
import OfferForm from "../../components/OfferForm";
import {
  getRequestInfo,
  getSalesRequests,
  getShipments,
  toggleStar,
} from "../../services/contentService";
import { useDispatch, useSelector } from "react-redux";
import {
  selectScac,
  selectStatus,
  selectTrackedBy,
  selectView,
} from "../../store/slices/shipmentSlice";
import ShipmentTable from "../../components/ShipmentTable";
import ShipmentForm from "../../components/ShipmentForm";
import { useNavigate, useLocation } from "react-router-dom";
import shipmentInfoShip from "../../assets/shipmentInfoShip.png";
import Spinner from "../../components/Spinner";
import EmptyStatus from "../../components/EmptyStatus";

function SalesShipmentTracking() {
  const [activeTab, setActiveTab] = useState("");
  const [loading, setLoading] = useState(false);
  const [showRequestDetailsModal, setShowRequestDetailsModal] = useState(false);
  const [showSendOfferModal, setShowSendOfferModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [salesRequests, setSalesRequests] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(null);
  const [order, setOrder] = useState("desc");
  const [isShipmentTrackingCleared, setIsShipmentTrackingCleared] =
    useState(false); // For clearing the filters
  const [searchTerm, setSearchTerm] = useState("");
  const token = localStorage.getItem("token");
  const select = useSelector;
  const navigate = useNavigate();
  const location = useLocation();
  const view = select(selectView);
  const numberTypes = select(selectTrackedBy);
  const status = select(selectStatus);
  const shippingLine = select(selectScac);
  const user = localStorage.getItem("type");
  // const token = localStorage.getItem("token");

  const handleClose = () => {
    setShowRequestDetailsModal(false);
    fetchRequests();
  };

  // Adding the user type to the view based on the user type.
  const finalViewFilter = view
    .split(",")
    .map((item) => {
      if (item === "starred" || item === "not_starred") {
        return `${user}_${item}`;
      } else {
        return item;
      }
    })
    .join(",");

  const fetchRequests = async () => {
    const formData = new FormData();
    if (view !== "") formData.append("view", view);
    if (numberTypes !== "") formData.append("tracked_by", numberTypes);
    if (status !== "") formData.append("status", status);
    if (shippingLine !== "") formData.append("scac", shippingLine);
    const queryString = new URLSearchParams(formData).toString();
    const finalQueryString = queryString
      .replace(
        /scac=[^&]*/,
        isShipmentTrackingCleared ? "" : `scac=${shippingLine}`
      )
      .replace(
        /status=[^&]*/,
        isShipmentTrackingCleared ? "" : `status=${status}`
      )
      .replace(
        /tracked_by=[^&]*/,
        isShipmentTrackingCleared ? "" : `tracked_by=${numberTypes}`
      )
      .replace(
        /view=[^&]*/,
        isShipmentTrackingCleared ? "" : `view=${`${finalViewFilter}`}`
      );
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url:
        searchTerm !== ""
          ? `shipment/tracking/${user}/list?order=${order}&page=${pageNumber}&id=${searchTerm}${
              finalQueryString ? `${finalQueryString}` : ""
            } `
          : `shipment/tracking/${user}/list?order=${order}&page=${pageNumber}&${
              finalQueryString ? `${finalQueryString}` : ""
            } `,

      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
    };
    setLoading(true);
    const response = await getShipments(config);
    // console.log(response.data);
    if (response?.status === 200) {
      // console.log(response.data?.data);
      setSalesRequests(response.data);
      setLoading(false);
    } else if (response.status === 401) {
      setLoading(false);
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  const toggleFavorite = async (event, id) => {
    event.stopPropagation();

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: `/shipment/tracking/mark?id=${id}`,
      headers: {
        Authorization: `Token ${token}`,
      },
    };
    const response = await toggleStar(config);

    if (response?.status === 200) {
      fetchRequests();
      //console.log("success");
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  const handleSendOffer = () => {
    setShowRequestDetailsModal(false);
    setShowSendOfferModal(true);
  };
  const handleSend = () => {
    setShowSendOfferModal(false);
    setShowSuccessModal(true);
  };
  const handleSuccess = () => {
    setShowSuccessModal(false);
    fetchRequests();
  };
  useEffect(() => {
    fetchRequests();
  }, [searchTerm]);
  useEffect(() => {
    fetchRequests();
  }, [pageNumber]);
  useEffect(() => {
    fetchRequests();
    setPageNumber(1);
  }, [order]);
  useLayoutEffect(() => {
    fetchRequests();
    setCount(salesRequests?.count?.total_count);
  }, []);

  const sidebarWidth = useSelector((state) => state.sidebar.width);

  return (
    <>
      {user === "client" && count === 0 ? (
        <div className={styles["sales-requests-empty"]}>
          <img src={shipmentInfoShip} />
          <h2>You don’t have shipments to track</h2>
          <p>
            First request quotations on shipments, later your booked shipments
            will appear here
          </p>
          <MainButton
            handleSubmit={() =>
              navigate("/request-quote/shipping-details", {
                state: { previousPath: location.pathname },
              })
            }
          >
            Request Quotation
          </MainButton>
        </div>
      ) : (
        <div
          className={styles["sales-requests"]}
          style={{ "--sidebar-width": `${sidebarWidth}px` }}
        >
          <div className={styles["sales-requests-tabs"]}></div>
          <Actions
            fetchRequests={fetchRequests}
            setOrder={setOrder}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            placeholder={"Search by tracking number"}
            type="shipment"
            setShowSendOfferModal={setShowSendOfferModal}
            filters={salesRequests?.filters}
            setPageNumber={setPageNumber}
            isShipmentTrackingCleared={isShipmentTrackingCleared}
            setIsShipmentTrackingCleared={setIsShipmentTrackingCleared}
          />
          {loading ? (
            <Spinner />
          ) : (
            <>
              {salesRequests?.data?.length === 0 ? (
                <div className={styles["empty-status"]}>
                  <EmptyStatus title="You don’t have any relevant shipments under this category." />
                </div>
              ) : (
                <ShipmentTable
                  setShowModal={setShowRequestDetailsModal}
                  salesRequestsData={salesRequests}
                  toggleFavorite={(event, id) => toggleFavorite(event, id)}
                />
              )}
            </>
          )}

          {salesRequests?.data?.length > 0 && (
            <Pagination
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              itemsPerPage={salesRequests && salesRequests?.count?.offset}
              totalItems={salesRequests && salesRequests?.count?.total_count}
            />
          )}
          {/*Send Offer Modal */}
          <BaseModal
            title={"Add New Shipment"}
            isOpen={showSendOfferModal}
            handleClose={() => setShowSendOfferModal(false)}
          >
            <ShipmentForm
              setShowSuccessModal={setShowSuccessModal}
              setShowSendOfferModal={setShowSendOfferModal}
              errorMessage={"something went wrong please try again later "}
              filters={salesRequests?.filters}
            />
          </BaseModal>
          {/*Success Modal */}
          <BaseModal
            title={"Request & Client Details"}
            isOpen={showSuccessModal}
            handleClose={handleSuccess}
            isTitle={false}
          >
            <div className={styles["sales-requests-success-modal"]}>
              <img src={OfferSuccess} />
              <h3 className={styles["sales-requests-h3"]}>
                Shipment Successfully Added{" "}
              </h3>
              <h6 className={styles["sales-requests-h6"]}>
                Shipment has been added successfully and could now be tracked
                daily by both you and the associated client.{" "}
              </h6>

              <div className={styles["sales-requests-button-success"]}>
                <MainButton
                  className={styles["sales-requests-button-success-btn"]}
                  handleSubmit={handleSuccess}
                >
                  Done
                </MainButton>
              </div>
            </div>
          </BaseModal>
        </div>
      )}
    </>
  );
}

export default SalesShipmentTracking;
