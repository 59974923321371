import {
  APIProvider,
  Map,
  AdvancedMarker,
  Pin,
  InfoWindow,
  useMapsLibrary,
  useMap,
} from "@vis.gl/react-google-maps";
import { useState, useEffect } from "react";
import shipmentIcon from "../../assets/shipment_location.svg";
import pinLocationIcon from "../../assets/pin_location.svg";
import styles from "./index.module.scss";
import { dateFormatWithMonthInName } from "../../utils/formatDate";

function ShipmentTrackingMap({ lastestShipment }) {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const mapId = process.env.REACT_APP_GOOGLE_PUBLIC_MAP_ID;

  const [markers, setMarkers] = useState([]); // State to hold marker data
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [shipmentInfo, setShipmentInfo] = useState(false);
  const { searates_body } = lastestShipment;

  // For The Map To be displayed as expected.
  const data = lastestShipment?.searates_body;

  const containers = searates_body?.containers;
  const locations = searates_body?.locations;
  const route = searates_body?.route;
  const vessels = searates_body?.vessels;

  // Extract The Location based on comparing between the route location value with the each location id.
  let finalLocations;
  if (!route || !locations) {
    console.error("Undefined data");
  } else {
    finalLocations = Object.fromEntries(
      Object.entries(route)?.map(([key, value]) => {
        const matchedPort = locations?.find(
          (port) => port.id === value.location
        );
        return [
          key,
          {
            ...value,
            portDetails: matchedPort || null, // Add matched port details or null if not found
          },
        ];
      })
    );
  }
  const finalLocationsArray = Object.values(finalLocations);

  let selectedMarkerKey;
  for (const key in finalLocations) {
    if (finalLocations[key].location === selectedMarker?.id) {
      selectedMarkerKey = key;
    }
  }

  // Finding the point that its location value equals to the selected Marker.
  const matedLocation = finalLocationsArray.find(
    (item) => item?.location === selectedMarker?.id
  );

  const sortedContainerEvents = containers[0].events.sort(
    (a, b) => b.order_id - a.order_id
  );
  const lastEventVessel = sortedContainerEvents[0].vessel;
  // Finding the vessel that its id equals to the last event vessel (if number).
  const matchedVessel = vessels.find((vessel) => vessel.id === lastEventVessel);

  // console.log("searatesbody", data);
  // console.log("sortedContainerEvents", sortedContainerEvents);
  // console.log("vessels", vessels);
  // console.log("locations", locations);
  // console.log("facilities", facilities);
  // console.log("matchedVessel", matchedVessel);
  // console.log("finalLocations", finalLocations);
  // console.log("finalLocationsArray", finalLocationsArray);
  // console.log("selectedMarkerKey", selectedMarkerKey);
  // console.log("matedLocation", matedLocation);

  const shipmentPosition = {
    lat: data.route_data.pin[0],
    lng: data.route_data.pin[1],
  };

  const routes = data.route_data.route; // The Shipment Routes Data

  // Markers Locations
  useEffect(() => {
    const locations = data.locations;
    setMarkers(locations);
  }, []);

  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };
  const handleCloseInfoWindow = () => {
    setSelectedMarker(null); // Close the InfoWindow
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        minHeight: "300px",
      }}
    >
      <APIProvider apiKey={apiKey} libraries={["marker"]}>
        <Map
          mapId={mapId}
          defaultZoom={2}
          defaultCenter={shipmentPosition}
          gestureHandling={"greedy"}
          disableDefaultUI={false}
          options={{
            restriction: {
              latLngBounds: {
                north: 85, // Northern hemisphere latitude limit
                south: -85, // Southern hemisphere latitude limit
                west: -180, // Western longitude limit
                east: 180, // Eastern longitude limit
              },
              strictBounds: true, // Prevent moving outside the bounds
            },
            minZoom: 1, // Limit zoom to a reasonable level so the whole map is visible
          }}
        >
          {/* Render All The Locations Markers */}
          {markers.map((marker) => (
            <AdvancedMarker
              key={marker.id}
              position={{ lat: marker.lat, lng: marker.lng }}
              onClick={() => handleMarkerClick(marker)}
            >
              <img src={pinLocationIcon} width={40} height={40} />
            </AdvancedMarker>
          ))}

          {/* Shipment Marker */}
          <AdvancedMarker
            position={shipmentPosition}
            onClick={() => setShipmentInfo(true)}
          >
            <img src={shipmentIcon} width={40} height={40} />
          </AdvancedMarker>

          {/* Render InfoWindow if a marker is selected */}
          {selectedMarker && (
            <InfoWindow
              position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
              onCloseClick={handleCloseInfoWindow}
            >
              <div className={styles["info-window"]}>
                <div className={styles["info-window-header"]}>
                  <span>{selectedMarker.name}</span>
                  <span>
                    {selectedMarkerKey ? `(${selectedMarkerKey})` : ""}
                  </span>
                </div>
                <p className={styles["info-window-content"]}>
                  {selectedMarker.state}, {selectedMarker.country}
                </p>
                {selectedMarkerKey && matedLocation && (
                  <div className={styles["info-window-footer"]}>
                    <div className={styles["info-window-footer-date"]}>
                      {selectedMarkerKey === "pol"
                        ? "Departure: "
                        : "Arrival: "}
                      <span>
                        {dateFormatWithMonthInName(matedLocation?.date)}
                      </span>
                    </div>
                    {matedLocation?.actual === "True" ? (
                      <span className={styles["date-actual"]}>Confirmed</span>
                    ) : (
                      <span className={styles["date-notActual"]}>
                        Estimated
                      </span>
                    )}
                  </div>
                )}
              </div>
            </InfoWindow>
          )}

          {/* Shipment Information */}
          {shipmentInfo && (
            <InfoWindow
              position={shipmentPosition}
              onCloseClick={() => setShipmentInfo(false)}
            >
              <>
                {!matchedVessel ? (
                  <div className={styles["info-window-header"]}>
                    Your Shipment
                  </div>
                ) : (
                  <div className={styles["info-window"]}>
                    <div className={styles["info-window-header"]}>
                      {matchedVessel?.name}
                    </div>
                    <div className={styles["info-window-containerInfo"]}>
                      <div>
                        <span>IMO: </span>
                        <span>{matchedVessel?.imo}</span>
                      </div>
                      <div>
                        <span>MMSI: </span>
                        <span>{matchedVessel?.mmsi}</span>
                      </div>
                      <div>
                        <span>Call Sign: </span>
                        <span>{matchedVessel?.call_sign}</span>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </InfoWindow>
          )}

          {/* Directions */}
          <Routes routes={routes} />
        </Map>
      </APIProvider>
    </div>
  );
}

export default ShipmentTrackingMap;

// The Routes Component
function Routes({ routes }) {
  // Drawing The Line
  const map = useMap();
  const routesLibrary = useMapsLibrary("routes");

  const [directionsService, setDirectionsService] = useState();
  const [directionsRenderer, setDirectionsRenderer] = useState();

  // Initialize The DirectionsService and DirectionsRenderer
  useEffect(() => {
    if (!map || !routesLibrary) return;

    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));
  }, [map, routesLibrary]);

  // Draw polyline for multiple sea routes
  useEffect(() => {
    if (!map) return;

    // Function to create polylines
    routes.forEach((route) => {
      const polylinePath = route.path.map((coords) => ({
        lat: coords[0],
        lng: coords[1],
      }));

      const polyline = new window.google.maps.Polyline({
        path: polylinePath,
        geodesic: true,
        strokeColor: route.type === "SEA" ? "#007bff" : "#333333", // Customize the color if needed
        strokeOpacity: 1,
        strokeWeight: 3,
      });

      // Set the polyline on the map
      polyline.setMap(map);
    });
  }, [map]);

  return null;
}
