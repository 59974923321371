import { useForm } from "react-hook-form";

export function useHookForm(props) {
  const {
    isFullName,
    isFirstName,
    isLastName,
    isEmail,
    isMessage,
    isPhone,
    isNonRequiredPhone,
    isRequiredPassword,
    isRequiredOldPassword,
    isPassword,
    isConfirmPassword,
    isCompany,
    isPosition,
    isTerms,
    isShipper,
    isState,
    isConsignee,
    isCommodity,
    isWeight,
    isHsCode,
    isPol,
    isPod,
    isShippingLine,
    isTransit,
    isTextArea,
    isTargetRate,
    defaultValues,
    values,
    mode = "onBlur",
    isUnNumber,
    isClassNumber,
    isTrackingNumber,
    isPodFreeTime,
    isInvoiceValue,
    isOriginAddress,
    isDestinationAddress,
    isOriginState,
    isDestinationState,
  } = props || {};

  const {
    register,
    handleSubmit,
    resetField,
    formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
    setValue,
    getValues,
    watch,
    trigger,
    clearErrors,
  } = useForm({ mode: "all", defaultValues, values });

  const phoneRegister =
    isPhone &&
    register("mobile_number", {
      onChange: (e) => {
        clearErrors("mobile_number");
        const phoneNumber = e.target.value;
        const trimmedNumber = phoneNumber.replace("/D/g", "");
        setValue("mobile_number", trimmedNumber);
      },
      required: {
        value: true,
        message: "Mobile number is required",
      },
      minLength: {
        value: 4,
        message: "invalid mobile number",
      },
      maxLength: {
        value: 20,
        message: "invalid mobile number",
      },
      validate: (value) => {
        const regex = /^[0-9]{4,}$/;
        return regex.test(value) || "Invalid mobile number";
      },
    });
  const nonRequiredPhoneRegister =
    isNonRequiredPhone &&
    register("phone_number", {
      onChange: (e) => {
        clearErrors("phone_number");
        const phoneNumber = e.target.value;
        const trimmedNumber = phoneNumber.replace(/\D/g, "");
        setValue("phone_number", trimmedNumber);
      },
      validate: (value) => {
        const regex = /^[0-9]{4,}$/;
        return regex.test(value) || "Invalid mobile number";
      },
      required: {
        value: true,
        message: "Mobile number is required",
      },
      minLength: {
        value: 4,
        message: "invalid mobile number",
      },
      maxLength: {
        value: 20,
        message: "invalid mobile number",
      },
    });
  const fullNameRegister =
    isFullName &&
    register("full_name", {
      onChange: (e) => {
        clearErrors("full_name");
        const full_name = e.target.value;
        const full_nameRegex =
          /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
        const displayValue = full_name
          .replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, "")
          .replace(/\s+/g, " ");
        if (full_nameRegex.test(displayValue)) {
          setValue("full_name", displayValue);
        } else {
          setValue("full_name", displayValue.trim());
        }
        if (!e.target.value) {
          resetField("full_name");
        }
      },
      required: {
        value: true,
        message: "Full name is required",
      },
      maxLength: {
        value: 20,
        message: "Not more than 20 characters",
      },
    });
  const firstNameRegister =
    isFirstName &&
    register("first_name", {
      onChange: (e) => {
        clearErrors("first_name");
        const first_name = e.target.value;
        const first_nameRegex =
          /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
        const displayValue = first_name

          .replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, "")
          .replace(/\s+/g, " ");
        if (first_nameRegex.test(displayValue)) {
          setValue("first_name", displayValue);
        } else {
          setValue("first_name", displayValue.trim());
        }
        // if (!e.target.value) {
        //   resetField("first_name");
        // }
      },
      required: {
        value: true,
        message: "First name is required",
      },
      maxLength: {
        value: 20,
        message: "Not more than 20 characters",
      },
      minLength: {
        value: 2,
        message: "At least 2 characters long",
      },
      validate: (value) => {
        const trimmedValue = value.trim();
        if (trimmedValue.length < 2) {
          return "First name must be at least 2 characters excluding spaces";
        }
        return true;
      },
    });
  const lastNameRegister =
    isLastName &&
    register("last_name", {
      onChange: (e) => {
        clearErrors("last_name");
        const last_name = e.target.value;
        const last_nameRegex =
          /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
        const displayValue = last_name
          .replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, "")
          .replace(/\s+/g, " ");
        if (last_nameRegex.test(displayValue)) {
          setValue("last_name", displayValue);
        } else {
          setValue("last_name", displayValue.trim());
        }
      },
      required: {
        value: true,
        message: "Last name is required",
      },
      maxLength: {
        value: 20,
        message: "Not more than 20 characters",
      },
      minLength: {
        value: 2,
        message: "At least 2 characters long",
      },
      validate: (value) => {
        const trimmedValue = value.trim();
        if (trimmedValue.length < 2) {
          return "First name must be at least 2 characters excluding spaces";
        }
        return true;
      },
    });

  const shipperRegister =
    isShipper &&
    register("shipper", {
      onChange: (e) => {
        clearErrors("shipper");
        const shipper = e.target.value;
        const shipperRegex =
          /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
        const displayValue = shipper
          .replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, "")
          .replace(/\s+/g, " ");
        if (shipperRegex.test(displayValue)) {
          setValue("shipper", displayValue);
        } else {
          setValue("shipper", displayValue.trim());
        }
      },
      maxLength: {
        value: 20,
        message: "Max characters has been exceeded",
      },
    });

  const consigneeRegister =
    isConsignee &&
    register("consignee", {
      onChange: (e) => {
        clearErrors("consignee");
        const consignee = e.target.value;
        const consigneeRegex =
          /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
        const displayValue = consignee
          .replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, "")
          .replace(/\s+/g, " ");
        if (consigneeRegex.test(displayValue)) {
          setValue("consignee", displayValue);
        } else {
          setValue("consignee", displayValue.trim());
        }
      },
      maxLength: {
        value: 20,
        message: "Max characters has been exceeded",
      },
    });

  const stateRegister =
    isState &&
    register("state", {
      onChange: (e) => {
        clearErrors("state");
        const state = e.target.value;
        const stateRegex = /^((?:[A-Za-z \u0621-\u064A\u0660-\u0669]+ ?){1,})$/;
        const displayValue = state
          .replace(/[^a-zA-Z \u0621-\u064A\u0660-\u0669]/g, "")
          .replace(/\s+/g, " ");
        if (stateRegex.test(displayValue)) {
          setValue("state", displayValue);
        } else {
          setValue("state", displayValue.trim());
        }
      },
      maxLength: {
        value: 20,
        message: "Max characters has been exceeded",
      },
    });

  const originStateRegister =
    isOriginState &&
    register("originState", {
      onChange: (e) => {
        clearErrors("originState");
        setValue("originState", e.target.value);
      },
      required: {
        value: true,
        message: "Origin state is required",
      },
    });
  const destinationStateRegister =
    isDestinationState &&
    register("destinationState", {
      onChange: (e) => {
        clearErrors("destinationState");
        setValue("destinationState", e.target.value);
      },
      required: {
        value: true,
        message: "Destination state is required",
      },
    });

  const originAddressRegister =
    isOriginAddress &&
    register("originAddress", {
      onChange: (e) => {
        clearErrors("originAddress");
        setValue("originAddress", e.target.value);
      },
      required: {
        value: true,
        message: "Origin address is required",
      },
    });

  const destinationAddressRegister =
    isDestinationAddress &&
    register("destinationAddress", {
      onChange: (e) => {
        clearErrors("destinationAddress");
        setValue("destinationAddress", e.target.value);
      },
      required: {
        value: true,
        message: "Destination address is required",
      },
    });

  const companyRegister =
    isCompany &&
    register("company", {
      onChange: (e) => {
        clearErrors("company");
        setValue("company", e.target.value);
      },
      required: {
        value: true,
        message: "Company name is required",
      },
      minLength: {
        value: 2,
        message: "At least 2 characters long",
      },
      maxLength: {
        value: 40,
        message: "Not more than 40 characters",
      },
      validate: (value) => {
        const trimmedValue = value.trim();
        if (trimmedValue.length < 2) {
          return "First name must be at least 2 characters excluding spaces";
        }
        return true;
      },
    });

  const positionRegister =
    isPosition &&
    register("position", {
      onChange: (e) => {
        clearErrors("position");
        setValue("position", e.target.value);
      },
      minLength: {
        value: 2,
        message: "At least 2 characters long",
      },
      maxLength: {
        value: 40,
        message: "Not more than 40 characters",
      },
      validate: (value) => {
        if (!value) {
          // If value is empty or undefined, allow it to pass validation
          return true;
        }
        const trimmedValue = value.trim();
        if (trimmedValue.length < 2) {
          return "Position must be at least 2 characters excluding spaces";
        }
        return true;
      },
    });

  const textAreaRegister =
    isTextArea &&
    register("textArea", {
      onChange: (e) => {
        clearErrors("textArea");
        setValue("textArea", e.target.value);
      },
      maxLength: {
        value: 300,
        message: "Max characters has been exceeded",
      },
    });

  const targetRateRegister =
    isTargetRate &&
    register("targetRate", {
      onChange: (e) => {
        clearErrors("targetRate");
        setValue("targetRate", e.target.value);
      },
      validate: (value) => {
        if (!value) return true;
        else {
          const regex = /^\d+$/;
          return regex.test(value) || "Please enter integer numbers only";
        }
      },
    });

  const hsCodeRegister =
    isHsCode &&
    register("hsCode", {
      onChange: (e) => {
        clearErrors("hsCode");
        setValue("hsCode", e.target.value);
      },
      required: {
        value: true,
        message: "HS code is required",
      },
    });

  const commodityRegister =
    isCommodity &&
    register("commodity", {
      onChange: (e) => {
        clearErrors("commodity");
        setValue("commodity", e.target.value);
      },
      required: {
        value: true,
        message: "Commodity is required",
      },
    });

  const unNumberRegister =
    isUnNumber &&
    register("unNumber", {
      onChange: (e) => {
        clearErrors("unNumber");
        setValue("unNumber", e.target.value);
      },
      required: {
        value: true,
        message: "UN number is required",
      },
    });

  const classNumberRegister =
    isClassNumber &&
    register("classNumber", {
      onChange: (e) => {
        clearErrors("classNumber");
        setValue("classNumber", e.target.value);
      },
      required: {
        value: true,
        message: "Class number is required",
      },
    });
  const trackingNumberRegister =
    isTrackingNumber &&
    register("trackingNumber", {
      onChange: (e) => {
        clearErrors("trackingNumber");
        setValue("trackingNumber", e.target.value);
      },
      required: {
        value: true,
        message: "tracking number is required",
      },
    });

  const PodFreeTimeRegister =
    isPodFreeTime &&
    register("PodFreeTime", {
      onChange: (e) => {
        clearErrors("PodFreeTime");
        setValue("PodFreeTime", e.target.value);
      },
      required: {
        value: true,
        message: "Pod free time is required",
      },
    });

  const invoiceValueRegister =
    isInvoiceValue &&
    register("invoiceValue", {
      onChange: (e) => {
        clearErrors("invoiceValue");
        setValue("invoiceValue", e.target.value);
      },
      required: {
        value: true,
        message: "Invoice value is required",
      },
    });

  const weightRegister =
    isWeight &&
    register("weight", {
      onChange: (e) => {
        clearErrors("weight");
        setValue("weight", e.target.value);
      },
      required: {
        value: true,
        message: "Weight is required",
      },
    });

  const podRegister =
    isPod &&
    register("pod", {
      onChange: (e) => {
        clearErrors("pod");
        setValue("pod", e.target.value);
      },
      required: {
        value: true,
        message: "Pod is required",
      },
    });

  const polRegister =
    isPol &&
    register("pol", {
      onChange: (e) => {
        clearErrors("pol");
        setValue("pol", e.target.value);
      },
    });

  const shippingLineRegister =
    isShippingLine &&
    register("shippingLine", {
      onChange: (e) => {
        clearErrors("shippingLine");
        setValue("shippingLine", e.target.value);
      },
      required: {
        value: true,
        message: "Shipping line is required",
      },
    });

  const transitRegister =
    isTransit &&
    register("transit", {
      onChange: (e) => {
        clearErrors("transit");
        setValue("transit", e.target.value);
      },
    });

  const emailRegister =
    isEmail &&
    register("email", {
      onChange: (e) => {
        clearErrors("email");
        setValue("email", e.target.value);
        clearErrors("email");
        // if (!e.target.value) {
        //   resetField("email");
        // }
      },
      required: {
        value: true,
        message: "Email is required",
      },
      validate: (value) => {
        if (!value) {
          return true;
        } else {
          const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          return regex.test(value) || "Email is not valid";
        }
      },
    });
  const requiredPasswordRegister =
    isRequiredPassword &&
    register("password", {
      onChange: (e) => {
        clearErrors("password");
        setValue("password", e.target.value);
        clearErrors("password");
        if (!e.target.value) {
          resetField("password");
        }
      },
      required: {
        value: true,
        message: "Password is required",
      },
      maxLength: {
        value: 40,
        message: "Max characters has been exceeded",
      },
    });
  const requiredOldPasswordRegister =
    isRequiredOldPassword &&
    register("currentPassword", {
      onChange: (e) => {
        clearErrors("currentPassword");
        setValue("currentPassword", e.target.value);
        clearErrors("currentPassword");
        if (!e.target.value) {
          resetField("currentPassword");
        }
      },
      required: {
        value: true,
        message: "Password is required",
      },
    });
  const passwordRegister =
    isPassword &&
    register("password", {
      onChange: (e) => {
        clearErrors("password");
        setValue("password", e.target.value);
        clearErrors("password");
        trigger("confirm-password");
        if (!e.target.value) {
          resetField("password");
        }
      },
      required: {
        value: true,
        message: "Password is required",
      },
      minLength: {
        value: 8,
        message: "at least 8 characters",
      },
      maxLength: {
        value: 40,
        message: "Max characters has been exceeded",
      },
      validate: (value) => {
        const regex =
          /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        return regex.test(value) || "Please enter a strong password";
      },
    });
  const confirmPasswordRegister =
    isConfirmPassword &&
    register("confirm-password", {
      onChange: (e) => {
        clearErrors("password");
        setValue("confirm-password", e.target.value);
        clearErrors("confirm-password");
        if (!e.target.value) {
          resetField("confirm-password");
        }
      },
      required: {
        value: true,
        message: "Password is required",
      },

      validate: (value) => {
        return getValues("password") === value || "Passwords should match";
      },
    });
  const termsRegister =
    isTerms &&
    register("accept-terms", {
      required: {
        value: true,
        message: "*",
      },
    });
  const messageRegister =
    isMessage &&
    register("message", {
      required: {
        value: true,
        message: "You must write a message",
      },
    });

  return {
    handleSubmit,
    errors,
    isDirty,
    isValid,
    emailRegister,
    phoneRegister,
    fullNameRegister,
    firstNameRegister,
    lastNameRegister,
    passwordRegister,
    requiredPasswordRegister,
    requiredOldPasswordRegister,
    confirmPasswordRegister,
    termsRegister,
    messageRegister,
    shipperRegister,
    stateRegister,
    consigneeRegister,
    commodityRegister,
    weightRegister,
    hsCodeRegister,
    podRegister,
    polRegister,
    shippingLineRegister,
    transitRegister,
    textAreaRegister,
    targetRateRegister,
    unNumberRegister,
    classNumberRegister,
    PodFreeTimeRegister,
    invoiceValueRegister,
    originStateRegister,
    destinationStateRegister,
    originAddressRegister,
    destinationAddressRegister,
    trackingNumberRegister,
    getValues,
    watch,
    dirtyFields,
    isSubmitting,
    clearErrors,
    setValue,
    companyRegister,
    positionRegister,
    nonRequiredPhoneRegister,
    register,
  };
}
