import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import Button from "../../assets/Button.svg";
import premiumClientIcon from "../../assets/premium_client_icon.svg";
import { useDispatch } from "react-redux";
import salesRequests from "../../store/slices/salesRequestsSlice";
import QuoteModal from "../quoteModal";

import { viewRequest } from "../../services/contentService";
import { useNavigate } from "react-router-dom";
const StarIcon = ({ filled }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={filled ? "#FFC107" : "none"}
      stroke="#FFC107"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="12 2 15 8.5 22 9.3 17 14 18.5 21 12 17 5.5 21 7 14 2 9.3 9 8.5 12 2" />
    </svg>
  );
};

// You can also use SASS for styling

const statusClasses = {
  planned: styles.new,
  "in-transit": styles.active,
  delivered: styles.accepted,
  unknown: styles.expired,
  error: styles.pending,
};

const ShipmentTable = ({ setShowModal, salesRequestsData, toggleFavorite }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = localStorage.getItem("user");
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const token = localStorage.getItem("token");
  const toggleDropdown = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };
  const handleViewClick = async (id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `content/request/seen?id=${id}`,
      headers: {
        Authorization: `token ${token}`,
      },
    };
    const response = await viewRequest(config);
    if (response?.status === 200) {
      dispatch(salesRequests.setOpenedRequestID(id));
      setShowModal(true);
      setOpenDropdownIndex(null);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };

  const formatDate = (date) => {
    const dateObj = new Date(date);
    let hours = dateObj.getHours();
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    const am_pm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    const day = dateObj.getDate().toString().padStart(2, "0");
    const year = dateObj.getFullYear();
    const month = dateObj.toLocaleString("en-US", { month: "short" });
    const formattedDate = `${hours}:${minutes}${am_pm} - ${day} ${month} ${year}`;

    return formattedDate;
  };

  return (
    <div className={styles.shipmentTrackingContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th style={{ borderRadius: "1rem 0px 0px" }}></th>

            <th style={{ minWidth: "180px" }}>
              <p>Tracking No.</p>
            </th>
            <th>
              <p style={{ minWidth: "max-content" }}>Shipping Line</p>
            </th>
            <th>Route</th>
            <th></th>
            <th></th>
            <th></th>
            <th style={{ minWidth: "130px" }}>Client</th>
            {/* <th>
              <p style={{ minWidth: "max-content" }}>Company</p>
            </th> */}
            <th>Commodity</th>
            <th style={{ borderRadius: "0px 1rem 0px 0px" }}>Status</th>
          </tr>
        </thead>
        <tbody>
          {salesRequestsData?.data.map((row, index) => (
            <tr
              key={index}
              onClick={() =>
                navigate("/shipment-info", {
                  state: { trackingNumber: row.tracking_number },
                })
              }
              className={styles.tableRow}
            >
              <td>
                <div onClick={(event) => toggleFavorite(event, row.id)}>
                  <StarIcon filled={row.client_starred ?? row.sales_starred} />
                </div>
              </td>
              <td>
                {row.tracking_number} <br />
                <span style={{ textTransform: "capitalize" }}>
                  {row.tracking_type.replaceAll("_", " ")}
                </span>
              </td>

              <td>{row.shipping_line_name}</td>

              <td
                style={{ textAlign: "center", padding: "12px 20px 12px 20px" }}
                className={styles.route}
              >
                <p className={styles.port}>{`${row.pol_name}`}</p>
                <p className={styles.country}>{`${row.pol_country}`}</p>
              </td>
              <td
                style={{
                  textAlign: "center",
                  padding: "12px 30px 12px 10px",
                }}
              >
                <p>{">"}</p>
              </td>
              <td
                style={{
                  textAlign: "center",
                }}
                className={styles.route}
              >
                <p className={styles.port}>{`${row.pod_name} `}</p>
                <p className={styles.country}>{`${row.pod_country}`}</p>
              </td>
              <td>
                {row.client_tier === "premium" ? (
                  <img src={premiumClientIcon} width={20} height={20} />
                ) : (
                  <div style={{ width: "24px", height: "24px" }}></div>
                )}
              </td>
              <td
                style={{
                  minWidth: "140px",
                  maxWidth: "140px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  overflow: "hidden",
                }}
              >
                <p className={styles.port}>{row.client}</p>
                <p className={styles.country}>{row.client_company}</p>
              </td>
              <td>{row.commodity}</td>
              <td>
                <span
                  className={`${statusClasses[row.status]} ${styles.status}`}
                >
                  {row.status?.charAt(0).toUpperCase() +
                    row.status?.slice(1).replaceAll("-", " ")}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ShipmentTable;
