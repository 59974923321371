import { IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import editIcon from "../../assets/edit.svg";
import avatarPlaceholder from "../../assets/user-placeholder.webp";
import FormInput from "../../components/formInput";
import MainButton from "../../components/mainButton";
import PasswordValidations from "../../components/passwordValidations";
import { useHookForm } from "../../hooks/hook-form";
import {
  changePassword,
  editProfile,
  editProfileImage,
  getProfile,
} from "../../services/authService";
import { setProfile, userData } from "../../store/slices/userSlice";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { saveState } from "../../helpers/sessionStorage";
import EditConfirmationModal from "../../components/EditConfirmationModal";
import infoIcon from "../../assets/info-icon.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setMyAccountTitle } from "../../store/slices/myAccountTitleSlice";
import { use } from "i18next";

const Profile = () => {
  const [profileData, setProfileData] = useState(null);
  const [edit, setEdit] = useState(null);
  const [open, setOpen] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const [editType, setEditType] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [editMsg, setEditMsg] = useState("");
  const [isPictureLoading, setIsPictureLoading] = useState(false);
  const [toastId, setToastId] = useState(null);
  const { profile } = useSelector(userData);
  // const { token } = useSelector(userData);
  const token = localStorage.getItem("token");
  const code = localStorage.getItem("countryCode");
  const [countryCode, setCountryCode] = useState(code);
  const dispatch = useDispatch();
  const fileInputRef = useRef();
  const navigate = useNavigate();

  const dropdownRef = useRef(null);

  //personal data schema

  const personalDataSchema = {
    isFirstName: true,
    isLastName: true,
    isNonRequiredPhone: true,
    isEmail: true,
    isCompany: true,
    isPosition: true,
    values: {
      first_name: profile?.first_name ?? "",
      last_name: profile?.last_name ?? "",
      phone_number: profile?.phone_number?.split(" ")[1] ?? "",
      email: profile?.user_email ?? "",
      company: profile?.company_name ?? "",
      position: profile?.position ?? "",
    },
    defaultValues: {
      first_name: profile?.first_name ?? "",
      last_name: profile?.last_name ?? "",
      phone_number: profile?.phone_number ?? "",
      email: profile?.user_email ?? "",
      company: profile?.company_name ?? "",
      position: profile?.position ?? "",
    },
  };

  //password schema
  const passwordSchema = {
    isRequiredOldPassword: true,
    isConfirmPassword: true,
    isPassword: true,
    values: {
      currentPassword: "",
    },
    defaultValues: {
      currentPassword: "",
    },
  };

  const {
    handleSubmit,
    errors,
    fullNameRegister,
    firstNameRegister,
    lastNameRegister,
    nonRequiredPhoneRegister,
    companyRegister,
    positionRegister,
    requiredPasswordRegister,
    requiredOldPasswordRegister,
    confirmPasswordRegister,
    passwordRegister,
    getValues,
    resetField,
    setValue,
    clearErrors,
    register,
    watch,
    dirtyFields,
    isValid,
    isDirty,
  } = useHookForm(edit != "password" ? personalDataSchema : passwordSchema);

  // //console.log("errors", errors);

  const getProfileData = async () => {
    const response = await getProfile(token);
    if (response.status === 200) {
      setProfileData(response.data);
      dispatch(setProfile(response.data));
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    }
  };
  useEffect(() => {
    getProfileData();
  }, [isSuccess]);

  const handleFormSubmit = async (data) => {
    clearErrors(["password", "confirm-password"]);
    const formData = new FormData();
    let payload = {};
    if (data.first_name) {
      formData.append("first_name", data.first_name);
      payload.first_name = data.first_name;
    }
    if (data.last_name) {
      formData.append("last_name", data.last_name);
      payload.last_name = data.last_name;
    }
    if (data.phone_number) {
      formData.append("phone_number", data.phone_number);
      payload.phone_number = data.phone_number;
      payload.phone_number_code = countryCode;
    }
    if (data.company) {
      formData.append("company_name", data.company);
      payload.company_name = data.company;
    }

    formData.append("position", data.position);
    payload.position = data.position;

    // //console.log("payload : ", payload);

    const response = await editProfile(payload, false, token);
    if (response.status === 200) {
      dispatch(
        setProfile({ ...response.data.data, picture: profileData.picture })
      );
      setEdit(null);
      setIsSuccess(true);
      setIsError(false);
      setEditMsg(response.data.success);
    } else if (response.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    } else {
      setIsError(true);
      setIsSuccess(false);
      setEditMsg(response.data.error);
    }
    // //console.log("fromData : ", formData);
  };

  const handleChangePasswordSubmit = async (data) => {
    //console.log(data.currentPassword);
    const response = await changePassword(
      {
        old_password: data.currentPassword,
        new_password: data.password,
        confirm_password: data["confirm-password"],
      },
      token
    );

    if (response.status === 200) {
      setEdit(null);
      setIsSuccess(true);
      setIsError(false);
      setEditMsg(`${response.data.success}`);
    } else {
      // console.log("err", response);
      setIsError(true);
      setIsSuccess(false);
      setEditMsg(`Old ${response.data.error}`);
    }
  };

  useEffect(() => {
    if (edit === "personalData") {
      clearErrors(["password", "confirm-password"]);
    }
  }, [edit]);

  // Handle opening the file explorer
  const handleChangePhoto = () => {
    fileInputRef.current.click();
  };
  // Handle the file change when a new photo is selected
  const handlePhotoChange = async (e) => {
    setIsOpen(!isOpen);
    setIsPictureLoading(true);

    const file = e.target.files[0];
    if (!file) return;
    setProfilePicture(file);

    const result = await editProfileImage(file, "change", token);
    // console.log(result);
    if (result.status === 200) {
      //console.log("Image changed successfully:", result);
      setEditType("change");
      setIsPictureLoading(false);
      // To Update The Header Avatar At The Same Time.
      dispatch(setProfile({ ...profile, picture: URL.createObjectURL(file) }));
    } else if (result.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    } else {
      console.log("Error changing the image:", result);
    }
  };
  // Handle deleting the photo.
  const handlePhotoDelete = async () => {
    setIsOpen(!isOpen);
    // try {
    const result = await editProfileImage(null, "delete", token);
    if (result.status === 200) {
      //console.log("Image deleted successfully:", result);
      setEditType("delete");
      // To Delete The Header Avatar At The Same Time.
      dispatch(setProfile({ ...profile, picture: null }));
    } else if (result.status === 401) {
      navigate("/login", { replace: true });
      localStorage.removeItem("token");
    } else {
      console.log("Error deleting the image:", result);
    }
  };
  // handle showImage State.
  useEffect(() => {
    if (editType === "change" && profilePicture) {
      setShowImage(URL.createObjectURL(profilePicture));
    }
  }, [editType, profilePicture]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  // Toastifies
  useEffect(() => {
    if (isSuccess) {
      toast.success(editMsg, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        theme: "colored",
        autoClose: true,
        style: { width: "max-content" },
      });
    }
    if (isError) {
      toast.error(editMsg, {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        theme: "colored",
        autoClose: true,
        style: { width: "max-content" },
      });
    }
    if (isPictureLoading && !toastId) {
      const id = toast.info("Uploading new photo...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        theme: "dark",
        autoClose: false,
        style: { width: "max-content" },
      });
      setToastId(id);
    }
    // When photo upload is successful.
    if (!isPictureLoading && toastId) {
      toast.update(toastId, {
        autoClose: 3000,
        render: "Photo uploaded successfully!",
      });
      setToastId(null);
    }
  }, [isSuccess, isError, isPictureLoading]);

  useEffect(() => {
    dispatch(setMyAccountTitle(edit));
  }, [edit]);

  return (
    <>
      <ToastContainer />
      <EditConfirmationModal open={open} setOpen={setOpen} setEdit={setEdit} />
      <div className={styles["profile"]}>
        <h1 className={`page-title ${styles["profile-title"]}`}>
          {edit === "personalData"
            ? "Edit Personal Information"
            : edit === "password"
            ? "Change Password"
            : "My Account"}
        </h1>
        {!edit && (
          <>
            <section>
              {profileData?.remaining_edit_days === 0 ? (
                <IconButton onClick={() => setOpen(true)}>
                  <img src={editIcon} />
                </IconButton>
              ) : null}

              <div className={styles["image-row"]}>
                <div className={styles["image-row-image-container"]}>
                  <img
                    src={
                      editType === "change"
                        ? showImage ?? profile?.picture
                        : editType === "delete"
                        ? avatarPlaceholder
                        : profile?.picture ?? avatarPlaceholder
                    }
                    alt={profile?.user_name}
                  />
                  <div ref={dropdownRef}>
                    <img
                      src={editIcon}
                      className={styles["edit-icon"]}
                      onClick={() => setIsOpen(!isOpen)}
                    />

                    {isOpen && (
                      <div className={styles["dropDownOptions"]}>
                        <div className={styles["item"]}>
                          <a onClick={handleChangePhoto}>Change Photo</a>
                        </div>
                        {profile?.picture === null ||
                        profile?.picture ===
                          "https://seaportalbe.teu-eg.com/media/profile_pics/default.webp" ? null : (
                          <div className={styles["item"]}>
                            <a onClick={handlePhotoDelete}>Delete Photo</a>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    multiple={false}
                    hidden
                    accept=".png, .jpg, .jpeg, .webp"
                    onChange={handlePhotoChange}
                  />
                </div>
                <h3>
                  {profile?.first_name} {profile?.last_name}
                </h3>
              </div>
              <div className={styles["data"]}>
                <label>First name</label>
                <p>{profile?.first_name}</p>
              </div>
              <div className={styles["data"]}>
                <label>Last name</label>
                <p>{profile?.last_name}</p>
              </div>
              <div className={styles["data"]}>
                <label>Phone Number</label>
                <p>
                  {profile?.phone_number ??
                    "Click on the edit button to add a phone number"}
                </p>
              </div>
              <div className={styles["data"]}>
                <label>Company Name</label>
                <p>
                  {profile?.company_name ??
                    "Click on the edit button to add a company"}
                </p>
              </div>
              <div className={styles["data"]}>
                <label>Position</label>
                <p>
                  {profile?.position ??
                    "Click on the edit button to add a position"}
                </p>
              </div>
            </section>
            <section>
              <IconButton onClick={() => setEdit("password")}>
                <img src={editIcon} />
              </IconButton>
              <div className={styles["data"]}>
                <label>Password</label>
                <p>***********</p>
              </div>
            </section>
            <section>
              <div className={styles["data"]}>
                <label>Email</label>
                <p>{profile?.user_email}</p>
              </div>
            </section>
          </>
        )}
        {edit === "personalData" ? (
          <form noValidate onSubmit={handleSubmit(handleFormSubmit)}>
            <div className={styles["field"]}>
              <p>First Name</p>
              <FormInput
                name="first_name"
                error={errors["first_name"]}
                placeholder={"Enter your first name"}
                formRegister={firstNameRegister}
              />
            </div>
            <div className={styles["field"]}>
              <p>Last Name</p>
              <FormInput
                name="last_name"
                error={errors["last_name"]}
                placeholder={"Enter your last name"}
                formRegister={lastNameRegister}
              />
            </div>
            <div className={styles["field"]}>
              <p>Phone number</p>
              <FormInput
                name="phone_number"
                error={errors["phone_number"]}
                placeholder={profile?.phone_number ?? "Enter your phone number"}
                formRegister={nonRequiredPhoneRegister}
                phone={true}
                setCode={setCountryCode}
              />
            </div>
            <div className={styles["field"]}>
              <p>Company name</p>
              <FormInput
                name="company"
                error={errors["company"]}
                placeholder={profile?.company ?? "Enter company name"}
                formRegister={companyRegister}
              />
            </div>
            <div className={styles["field"]}>
              <p>Position</p>
              <FormInput
                name="position"
                error={errors["position"]}
                placeholder={"Enter your position"}
                formRegister={positionRegister}
              />
            </div>
            <div className={styles["note"]}>
              <img src={infoIcon} alt="info-icon" />
              <p>
                Please note that you cannot change your information more
                frequently than once every 60 days.
              </p>
            </div>
            <div className={styles["buttons-container"]}>
              <MainButton handleSubmit={() => setEdit(null)}>Cancel</MainButton>
              <MainButton
                type={"submit"}
                disabled={!isValid || !isDirty}
                className={styles["saveBtn"]}
              >
                Save
              </MainButton>
            </div>
          </form>
        ) : edit === "password" ? (
          <form noValidate onSubmit={handleSubmit(handleChangePasswordSubmit)}>
            <div className={styles["field"]}>
              <p>Old password</p>
              <FormInput
                name="currentPassword"
                type="password"
                error={errors["currentPassword"]}
                placeholder={"Enter current password"}
                formRegister={requiredOldPasswordRegister}
                labelIconName="Lock"
                labelIcon={true}
              />
            </div>
            <div className={styles["field"]}>
              <p>New password</p>
              <FormInput
                name="password"
                type="password"
                error={errors["password"]}
                placeholder={"Enter new password"}
                formRegister={passwordRegister}
                labelIconName="Lock"
                labelIcon={true}
              />
            </div>
            <PasswordValidations
              isDirty={dirtyFields["password"]}
              error={errors["password"]}
              value={watch("password")}
              className={styles["validations"]}
            />
            <div className={styles["field"]}>
              <p>Confirm password</p>
              <FormInput
                name="confirm-password"
                type="password"
                error={errors["confirm-password"]}
                placeholder={"Re-enter new password"}
                formRegister={confirmPasswordRegister}
                labelIconName="Lock"
                labelIcon={true}
              />
            </div>
            <div className={styles["buttons-container"]}>
              <MainButton handleSubmit={() => setEdit(null)}>Cancel</MainButton>
              <MainButton
                disabled={!isValid || !isDirty}
                type="submit"
                className={styles["saveBtn"]}
              >
                Save
              </MainButton>
            </div>
          </form>
        ) : null}
      </div>
    </>
  );
};

export default Profile;
