import React, { useEffect, useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Radio,
  Typography,
} from "@mui/material";
import styles from "./index.module.scss";
import { useDispatch } from "react-redux";
import {
  setCargoType,
  setIsCargoTypeChanged,
} from "../../store/slices/searchSlice";

const DropdownWithRadioButtonSelect = ({
  options,
  label = "Cargo",
  placeholder = "Select cargo",
  className = "",
  selectedValue = null,
  setSelectedValue,
}) => {
  // console.log("options", options);
  return (
    <FormControl
      className={`${[styles["cargo-dropdown"], className].join(" ")}`}
    >
      <div className={styles["label"]}>
        <label>{label}</label>
      </div>

      <Select
        labelId="radio-dropdown-label"
        id="radio-dropdown"
        value={selectedValue}
        onChange={(event) => setSelectedValue(event.target.value)}
        displayEmpty
        renderValue={(selected) => {
          return (
            selected || (
              <Typography
                sx={{
                  color: "#B7B7B7",
                  fontSize: "0.875rem",
                  textTransform: "none",
                }}
              >
                {placeholder}
              </Typography>
            )
          );
        }}
        // Custom styling
        sx={{
          borderRadius: "0.5rem",
          textTransform: "capitalize",
          height: "2.875rem",

          // Accessing the select field.
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(238, 238, 238, 1)",
            borderWidth: "0.8px",
          },
          // The border custom styles on hover.
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 122, 255, 1)",
            borderWidth: "0.8px",
          },
          // The border custom styles on focus.
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgba(0, 122, 255, 1)",
            borderWidth: "0.8px",
          },
          // Accessing the dropdown arrow.
          "& .MuiSelect-icon": {
            display: "none",
          },
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem
              value={option}
              key={option.id}
              sx={{ textTransform: "capitalize" }}
            >
              <Radio checked={selectedValue === option.title} /> {option.title}
            </MenuItem>
          );
        })}
        {/* <MenuItem value="option1">
          <Radio checked={selectedValue === "option1"} /> Option 1
        </MenuItem>
        <MenuItem value="option2">
          <Radio checked={selectedValue === "option2"} /> Option 2
        </MenuItem>
        <MenuItem value="option3">
          <Radio checked={selectedValue === "option3"} /> Option 3
        </MenuItem> */}
      </Select>
    </FormControl>
  );
};

export default DropdownWithRadioButtonSelect;
