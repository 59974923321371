// CustomModal.jsx

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
} from "@mui/material";
import CloseIcon from "../../assets/Vector.png";
import styles from "./index.module.scss";
import DateInput from "../dateInput";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import moment from "moment/moment";
import ApplyButton from "../ApplyButton";
function VesselSearchFIlterModal({
  handleOpen,
  isOpen,
  bounds,
  dispatch,
  filters,
  setFiltersApplied,
  shouldShowPrice,
}) {
  const [open, setOpen] = useState(true);
  const [price, setPrice] = useState([null, null]);
  const [transit, setTransit] = useState([
    bounds.minTransitTime,
    bounds.maxTransitTime,
  ]);
  const [dateFields, setDateFields] = useState({
    etaFrom: null,
    etaTo: null,
    etdFrom: null,
    etdTo: null,
  });
  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };
  const handleTransitTimeChange = (event) => {
    setTransit(event.target.value);
  };
  const handleETAFrom = (from) => {
    const formattedDate = moment(from.$d).format("YYYY-MM-DD");
    setDateFields({
      ...dateFields,
      etaFrom: formattedDate,
    });
  };
  const handleETATo = (to) => {
    const formattedDate = moment(to.$d).format("YYYY-MM-DD");
    setDateFields({
      ...dateFields,
      etaTo: formattedDate,
    });
  };
  const handleETDFrom = (from) => {
    const formattedDate = moment(from.$d).format("YYYY-MM-DD");
    setDateFields({
      ...dateFields,
      etdFrom: formattedDate,
    });
  };
  const handleETDTo = (to) => {
    const formattedDate = moment(to.$d).format("YYYY-MM-DD");
    setDateFields({
      ...dateFields,
      etdTo: formattedDate,
    });
  };

  // console.log("filtes", filters);
  // console.log("bounds", bounds);
  // console.log("price", price);
  // console.log("transit", transit);

  const handleApplyClick = () => {
    setFiltersApplied(true);
    dispatch({ type: "SET_PRICE_RANGE", payload: price });
    dispatch({ type: "SET_TRANSIT_TIME_RANGE", payload: transit });
    dispatch({
      type: "SET_ETA_RANGE",
      payload: [dateFields.etaFrom, dateFields.etaTo],
    });
    dispatch({
      type: "SET_ETD_RANGE",
      payload: [dateFields.etdFrom, dateFields.etdTo],
    });
    handleOpen(false);
  };
  const handleClearClick = () => {
    dispatch({
      type: "SET_PRICE_RANGE",
      payload: [null, null],
    });
    setPrice([null, null]);
    dispatch({
      type: "SET_TRANSIT_TIME_RANGE",
      payload: [null, null],
    });
    setTransit([null, null]);
    dispatch({
      type: "SET_ETA_RANGE",
      payload: [null, null],
    });
    dispatch({
      type: "SET_ETD_RANGE",
      payload: [null, null],
    });

    setDateFields({ etaFrom: null, etaTo: null, etdFrom: null, etdTo: null });
    handleOpen(false);
  };

  const handleClose = () => {
    handleOpen(false);
  };

  // console.log("dateFields", dateFields);
  // console.log("filters", filters);
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        className: styles.customDialogPaper,
      }}
      classes={{ paper: styles.customDialogRoot }}
    >
      <DialogTitle>
        <div className={styles.titleBar}>
          <span>Filter by</span>
          <IconButton
            onClick={handleClose}
            size="small"
            className={styles.closeButton}
          >
            <img src={CloseIcon} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <aside className={styles.filtersSidebar}>
          <div className={styles.filters}>
            {shouldShowPrice ? (
              <div className={styles.filter}>
                <h3>Price</h3>
                <div className={styles.filter}>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "8px",
                      }}
                    >
                      <span>{price[0] ? price[0] : bounds.minPrice} $</span>
                      {/* Left label */}
                      <span>{price[1] ? price[1] : bounds.maxPrice} $</span>
                      {/* Right label */}
                    </div>
                    <Slider
                      value={[
                        price[0] ? price[0] : bounds.minPrice,
                        price[1] ? price[1] : bounds.maxPrice,
                      ]}
                      onChange={handlePriceChange}
                      valueLabelDisplay="auto"
                      min={bounds.minPrice}
                      max={bounds.maxPrice}
                      style={{ color: "#d32f2f" }}
                      valueLabelFormat={(value) => `${value} $`}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            <div className={styles.filter}>
              <h3>ETD</h3>
              <div className={styles.inputs}>
                <DateInput
                  theme={true}
                  label={"from"}
                  placeholderShowed={false}
                  handleChange={handleETDFrom}
                  value={dateFields.etdFrom}
                />

                <DateInput
                  theme={true}
                  label={"to"}
                  placeholderShowed={false}
                  handleChange={handleETDTo}
                  value={dateFields.etdTo}
                />
              </div>
            </div>
            <div className={styles.filter}>
              <h3>ETA</h3>
              <div className={styles.inputs}>
                <DateInput
                  theme={true}
                  label={"from"}
                  placeholderShowed={false}
                  handleChange={handleETAFrom}
                  value={dateFields.etaFrom}
                />
                <DateInput
                  theme={true}
                  label={"to"}
                  placeholderShowed={false}
                  handleChange={handleETATo}
                  value={dateFields.etaTo}
                />
              </div>
            </div>
            <div className={styles.filter}>
              <h3>Transit</h3>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "8px",
                  }}
                >
                  <span>
                    {transit[0] ? transit[0] : bounds.minTransitTime} days
                  </span>
                  {/* Left label */}
                  <span>
                    {transit[1] ? transit[1] : bounds.maxTransitTime} days
                  </span>{" "}
                  {/* Right label */}
                </div>
                <Slider
                  value={[
                    transit[0] ? transit[0] : bounds.minTransitTime,
                    transit[1] ? transit[1] : bounds.maxTransitTime,
                  ]}
                  onChange={handleTransitTimeChange}
                  valueLabelDisplay="auto"
                  min={bounds.minTransitTime}
                  max={bounds.maxTransitTime}
                  style={{ color: "#d32f2f" }} // Custom color for the slider
                  valueLabelFormat={(value) => `${value} days`}
                />
              </div>
            </div>
            <div className={styles.filterButtons}>
              <Button
                variant="outlined"
                sx={{ textTransform: "capitalize" }}
                onClick={handleClearClick}
              >
                clear
              </Button>
              <Button
                variant="contained"
                sx={{ textTransform: "capitalize" }}
                onClick={handleApplyClick}
              >
                apply
              </Button>
            </div>
          </div>
        </aside>
      </DialogContent>
    </Dialog>
  );
}

export default VesselSearchFIlterModal;
