import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import Button from "../../assets/Button.svg";
import { useDispatch } from "react-redux";
import salesRequests from "../../store/slices/salesRequestsSlice";
import QuoteModal from "../quoteModal";
import { t } from "i18next";
import { toggleStar, viewRequest } from "../../services/contentService";
import { useNavigate } from "react-router-dom";
import premiumClientIcon from "../../assets/premium_client_icon.svg";
import arrowRight from "../../assets/arrow-right.svg";
import { tableDateFormat } from "../../utils/formatDate";

const StarIcon = ({ filled }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={filled ? "#FFC107" : "none"}
      stroke="#FFC107"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polygon points="12 2 15 8.5 22 9.3 17 14 18.5 21 12 17 5.5 21 7 14 2 9.3 9 8.5 12 2" />
    </svg>
  );
};

// You can also use SASS for styling

const statusClasses = {
  active: styles.active,
  new_requests: styles.new,
  expired: styles.expired,
  accepted: styles.accepted,
  pending: styles.pending,
};

const RequestsTable = ({
  setShowModal,
  salesRequestsData,
  toggleFavorite,
  fetchRequests,
}) => {
  const dispatch = useDispatch();
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const dropdownRef = useRef(null);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const toggleDropdown = (index) => {
    setOpenDropdownIndex(openDropdownIndex === index ? null : index);
  };
  const handleViewClick = async (id) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `content/request/seen?id=${id}`,
      headers: {
        Authorization: `token ${token}`,
      },
    };
    const response = await viewRequest(config);
    if (response?.status === 200) {
      dispatch(salesRequests.setOpenedRequestID(id));
      // setShowModal(true);
      // setOpenDropdownIndex(null);
    } else {
      //console.log("error");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdownIndex(false);
        // setShowOfferModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);
  return (
    <div className={styles["sales-requests-table"]}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th style={{ borderRadius: "1rem 0 0 0" }}></th>
            <th>ID</th>
            <th>Time & Date</th>
            {/* <th style={{ display: "flex" }}>
              <div style={{ width: "24px", height: "24px" }}></div>
              <p>Client</p>
            </th> */}
            <th></th>
            <th style={{ minWidth: "130px" }}>Client</th>
            <th>Route</th>
            <th></th>
            <th></th>
            <th style={{ minWidth: "250px", maxWidth: "250px" }}>Commodity</th>
            <th>Status</th>
            <th style={{ borderRadius: "0 1rem 0 0" }}></th>
          </tr>
        </thead>
        <tbody>
          {salesRequestsData?.data.map((row, index) => (
            <tr
              key={index}
              className={`${
                ((row?.request_status === "pending" && !row?.seen) ||
                  (row?.request_status === "accepted" && !row?.seen)) &&
                styles["unseen-row"]
              }`}
            >
              <td>
                <div onClick={() => toggleFavorite(row.id)}>
                  <StarIcon filled={row.marked} />
                </div>
              </td>
              <td>{row.id}</td>
              <td style={{ minWidth: "170px" }}>
                {tableDateFormat(row.created_at)}
              </td>
              <td>
                {row.client_tier === "premium" ? (
                  <img src={premiumClientIcon} width={20} height={20} />
                ) : (
                  <div style={{ width: "24px", height: "24px" }}></div>
                )}
              </td>
              <td
                style={{
                  minWidth: "140px",
                  maxWidth: "140px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  overflow: "hidden",
                }}
              >
                <p className={styles.port}>
                  {row.client_fname + " " + row.client_lname}
                </p>
                <p className={styles.country}>{row.client_company}</p>
              </td>
              <td
                style={{ textAlign: "left", padding: "12px 0px 12px 20px" }}
                className={styles.route}
              >
                <p
                  className={styles.port}
                  style={{
                    minWidth: "140px",
                    maxWidth: "140px",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    overflow: "hidden",
                  }}
                >{`${row.port_of_loading} `}</p>
                <p className={styles.country}>{`${row.pol_country_name}`}</p>
              </td>
              <td>
                <img src={arrowRight} alt="arrow-right" />
              </td>
              <td
                style={{
                  textAlign: "left",
                }}
                className={styles.route}
              >
                <p
                  className={styles.port}
                  style={{
                    minWidth: "140px",
                    maxWidth: "140px",
                    wordWrap: "break-word",
                    whiteSpace: "normal",
                    overflow: "hidden",
                  }}
                >{`${row.port_of_discharge} `}</p>
                <p className={styles.country}>{`${row.pod_country_name}`}</p>
              </td>
              <td
                style={{
                  minWidth: "250px",
                  maxWidth: "250px",
                  wordWrap: "break-word",
                  whiteSpace: "normal",
                  overflow: "hidden",
                }}
              >
                {row.commodity}
              </td>
              <td>
                <span className={statusClasses[row.request_status]}>
                  {row.request_status.charAt(0).toUpperCase() +
                    row.request_status.slice(1)}
                </span>
              </td>
              <td className={styles.ellipsis}>
                <div className={styles.filterContainer}>
                  <button
                    className={styles.filterButton}
                    onClick={() => toggleDropdown(index)}
                  >
                    <img src={Button} width={32} height={32} />
                  </button>
                  {openDropdownIndex === index && (
                    <div
                      className={styles["filterContainer-dropdownMenu"]}
                      // ref={dropdownRef}
                    >
                      <div
                        className={styles["filterContainer-dropdownItem"]}
                        onClick={() => {
                          setShowModal(true);
                          if (row.request_status === "pending")
                            handleViewClick(row.id);
                        }}
                      >
                        View Details
                      </div>
                      {row.search_id && (
                        <div
                          className={styles["filterContainer-dropdownItem"]}
                          onClick={() =>
                            navigate("/search-history", {
                              state: {
                                searchId: row.search_id,
                                requestId: row.id,
                              },
                            })
                          }
                        >
                          Searched Results
                        </div>
                      )}

                      {["active", "accepted", "expired"].includes(
                        row.request_status
                      ) ? (
                        <div
                          onClick={() => {
                            setShowOfferModal(true);
                            if (!row.seen) handleViewClick(row.id);
                          }}
                          className={styles["filterContainer-dropdownItem"]}
                        >
                          View Quotation
                        </div>
                      ) : null}
                      {showOfferModal && (
                        <QuoteModal
                          handleClose={() => {
                            setShowOfferModal(false);
                            if (!row.seen) fetchRequests();
                            // setOpenDropdownIndex(null);
                          }}
                          isOpen={showOfferModal}
                          title={"Submitted Quotation"}
                          id={row.id}
                          pdfUrl={row.attachment}
                          expiration_date={row.expiration_date}
                          request_status={row.request_status}
                          role="sales"
                        />
                      )}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RequestsTable;
