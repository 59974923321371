import styles from "./index.module.scss";
import clock from "../../assets/clock-icon.svg";
import moment from "moment/moment";
import stateIcon from "../../assets/state.svg";
import truck from "../../assets/truck2.svg";
import ship from "../../assets/ship2.svg";
import { memo, useEffect, useState } from "react";
import { dateFormatWithMonthInName } from "../../utils/formatDate";
import axios from "axios";

const DashboardTimeline = ({ lastestShipment }) => {
  const {
    client,
    client_company,
    commodity,
    created_at,
    id,
    pod_country,
    pod_flag,
    pod_name,
    pol_country,
    pol_flag,
    pol_name,
    sales,
    sales_starred,
    scac,
    searates_body,
    shipping_line_logo,
    shipping_line_name,
    status,
    status_update,
    tracking_number,
    tracking_type,
    updated_at,
  } = lastestShipment;

  const containers = searates_body?.containers;
  const facilities = searates_body?.facilities;
  const locations = searates_body?.locations;
  const metadata = searates_body?.metadata;
  const route = searates_body?.route;
  const route_data = searates_body?.route_data;
  const vessels = searates_body?.route_data;

  // Checks if prePol and postPod exists.
  const prePolExists = route?.prepol?.location !== route?.pol?.location;
  const postPodExists = route?.postpod?.location !== route?.pod?.location;
  // For The Shipping Line Icon.
  const baseURL = axios.defaults.baseURL;

  // Extract The Location based on comparing between the route location value with the each location id.
  let finalLocations;
  if (!route || !locations) {
    console.error("Undefined data");
  } else {
    finalLocations = Object.fromEntries(
      Object.entries(route)?.map(([key, value]) => {
        const matchedPort = locations?.find(
          (port) => port.id === value.location
        );
        return [
          key,
          {
            ...value,
            portDetails: matchedPort || null, // Add matched port details or null if not found
          },
        ];
      })
    );
  }

  function getDaysBetween(date1, date2) {
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);
    const diffInMs = Math.abs(secondDate - firstDate);
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays;
  }

  const statusClasses = {
    planned: styles.new,
    "in-transit": styles.active,
    delivered: styles.accepted,
    unknown: styles.expired,
    error: styles.pending,
  };
  return (
    <div className={styles["card"]}>
      <div className={`${styles["card-row"]}`}>
        <div className={styles["card-row-header"]}>
          <p>
            {tracking_type?.replaceAll("_", " ")} <span>{tracking_number}</span>
          </p>
        </div>
        <div className={`${statusClasses[status]} ${styles.status}`}>
          <p>{status?.replaceAll("-", " ")}</p>
        </div>
      </div>

      <hr />
      {/* Request Card TimeLine */}
      <div className={styles["card-shipping-row"]}>
        {/* prePol Part */}
        {prePolExists && (
          <>
            {/* Content */}
            <div
              className={`${styles["card-shipping-row-item"]} ${styles["card-shipping-row-item-origin"]}`}
            >
              <>
                <p className={styles["port-date"]}>
                  Arrival:{" "}
                  <span>
                    {dateFormatWithMonthInName(finalLocations?.prepol.date)}
                  </span>
                </p>
                <img src={stateIcon} />
                <p className={styles["card-shipping-row-item-strong"]}>
                  {finalLocations?.prepol.portDetails.name}
                </p>
                <p className={styles["card-shipping-row-item-country"]}>
                  {finalLocations?.prepol.portDetails.state},{" "}
                  {finalLocations?.prepol.portDetails.country}
                </p>
              </>
            </div>
            {/* Separator */}
            <div
              className={`${styles["card-shipping-row-separator-container"]} 
            ${styles["card-shipping-row-separator-container-origin"]}`}
            >
              <img src={truck} />
              <div className={styles["card-shipping-row-separator"]}></div>
            </div>
          </>
        )}
        {/* Port Of Loading */}
        <div
          className={`${styles["card-shipping-row-item"]} ${styles["card-shipping-row-item-pol"]}`}
        >
          <p className={styles["port-date"]}>
            Departure:{" "}
            <span>{dateFormatWithMonthInName(finalLocations?.pol.date)}</span>
          </p>
          <img src={pol_flag} />
          <p className={styles["card-shipping-row-item-strong"]}>
            {finalLocations?.pol.portDetails.name}
          </p>
          <p className={styles["card-shipping-row-item-country"]}>
            {finalLocations?.pol.portDetails.state},{" "}
            {finalLocations?.pol.portDetails.country}
          </p>
        </div>
        {/* The Blue Separator */}
        <div className={styles["card-shipping-row-separator-container"]}>
          <div className={styles["transit"]}>
            <img src={ship} />
            <span>
              {getDaysBetween(
                finalLocations?.pod.date,
                finalLocations?.pol.date
              )}{" "}
              days
            </span>
          </div>
          <div
            className={`${styles["card-shipping-row-separator"]} ${styles["card-shipping-row-blue-separator"]}`}
          ></div>
        </div>
        {/* Port Of Discharge */}
        <div
          className={`${styles["card-shipping-row-item"]} ${styles["card-shipping-row-item-pod"]}`}
        >
          <p className={styles["port-date"]}>
            Arrival:{" "}
            <span>{dateFormatWithMonthInName(finalLocations?.pod.date)}</span>
          </p>
          <img src={pod_flag} />
          <p className={styles["card-shipping-row-item-strong"]}>
            {finalLocations?.pod.portDetails.name}
          </p>
          <p className={styles["card-shipping-row-item-country"]}>
            {finalLocations?.pod.portDetails.state},{" "}
            {finalLocations?.pod.portDetails.country}
          </p>
        </div>
        {/* postPod Part */}
        {postPodExists && (
          <>
            {/* Separator */}
            <div
              className={`${styles["card-shipping-row-separator-container"]} 
          ${styles["card-shipping-row-separator-container-destination"]}`}
            >
              <img src={truck} />
              <div className={styles["card-shipping-row-separator"]}></div>
            </div>
            {/* Content */}
            <div
              className={`${styles["card-shipping-row-item"]} ${styles["card-shipping-row-item-destination"]}`}
            >
              <p className={styles["port-date"]}>
                Arrival:{" "}
                <span>
                  {dateFormatWithMonthInName(finalLocations?.postpod.date)}
                </span>
              </p>
              <img src={stateIcon} />
              <p className={styles["card-shipping-row-item-strong"]}>
                {finalLocations?.postpod.portDetails.name}
              </p>
              <p className={styles["card-shipping-row-item-country"]}>
                {finalLocations?.postpod.portDetails.state},{" "}
                {finalLocations?.postpod.portDetails.country}
              </p>
            </div>
          </>
        )}
      </div>
      <hr />

      <div className={`${styles["card-row"]}`}>
        <div className={styles["timeline-footer"]}>
          <div className={styles["timeline-footer-item"]}>
            <img
              src={`${baseURL}${shipping_line_logo}`}
              height={24}
              alt="shipping-line"
            />
            <h4>{shipping_line_name}</h4>
          </div>
          <div className={styles["timeline-footer-item"]}>
            <h3>Commodity:</h3>
            <p>{commodity}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(DashboardTimeline);
